// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {getStorage} from "firebase/storage";
import {getFirestore} from "firebase/firestore";

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCn0u1DXPYF1nXtVDKwmFs8n8cSTORC5XU",
    authDomain: "design-connect.firebaseapp.com",
    projectId: "design-connect",
    storageBucket: "design-connect.appspot.com",
    messagingSenderId: "524314496160",
    appId: "1:524314496160:web:d5ee9e83e2a91d1343cdc5",
    measurementId: "G-DTS412H2TR"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const db = getFirestore(app)
const analytics = getAnalytics(app);

export {storage, db, analytics}
