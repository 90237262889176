import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {Fab} from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const ScrollToTop = () => {
    const {pathname} = useLocation();

    useEffect(() => {
        // Scroll to top whenever the pathname changes
        window.scrollTo({top: 0, behavior: 'smooth'});
    }, [pathname]); // Depend on pathname to trigger effect

    // This function and state are now only for the "scroll to top" button
    const [showScroll, setShowScroll] = React.useState(false);

    useEffect(() => {
        const checkScrollTop = () => {
            if (!showScroll && window.pageYOffset > 1200) {
                setShowScroll(true);
            } else if (showScroll && window.pageYOffset <= 1200) {
                setShowScroll(false);
            }
        };

        window.addEventListener('scroll', checkScrollTop);
        return () => {
            window.removeEventListener('scroll', checkScrollTop);
        };
    }, [showScroll]);

    const scrollTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    };

    return (
        <div style={{display: showScroll ? 'flex' : 'none'}}>
            <Fab color='primary' size="small" onClick={scrollTop} style={{position: 'fixed', bottom: 20, right: 20}}>
                <ArrowUpwardIcon/>
            </Fab>
        </div>
    );
};

export default ScrollToTop;
