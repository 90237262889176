import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Home from "../Home/Home";
import "../Layout/Layout.css";
import CookieConsentBanner from "../CookieConsentBanner/CookieConsentBanner";
import Privacy from "../Privacy/Privacy";
import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";
import DesignersOverview from "../Designers/Overview/DesignersOverview";
import { DesignersProvider } from "../../context/DesignersContext";
import DesignersDetail from "../Designers/Detail/DesignersDetail";
import { Container, Grid } from "@mui/material";
import ScrollToTop from "../../utils/ScrollToTop";
import { BlogProvider } from "../../context/BlogContext";
import BlogsOverview from "../Blogs/Overview/BlogsOverview";
import BlogsDetail from "../Blogs/Detail/BlogsDetail";
import Contact from "../Contact/Contact";
import Faq from "../Faq/Faq";
import InspirationPage from "../Inspiration/InspirationPage";
import ScreenClassProvider from './ScreenClassProvider'; // Import the ScreenClassProvider

const Layout = () => (
    <DesignersProvider>
        <BlogProvider>
            <ScreenClassProvider> {/* Wrap the content with ScreenClassProvider */}
                <div className="layoutContainer">
                    <Navbar />
                    <CookieConsentBanner />
                    <ScrollToTop />
                    <Routes>
                        <Route path="/" element={<Home />} />
                    </Routes>
                    <div className="contentArea">
                        <Container maxWidth="xl">
                            <Grid container spacing={2}>
                                <Routes>
                                    <Route path="designers" element={<DesignersOverview />} />
                                    <Route path="designers/:designerId" element={<DesignersDetail />} />
                                    <Route path="inspiration" element={<InspirationPage />} />
                                    <Route path="blogs" element={<BlogsOverview />} />
                                    <Route path="blogs/:blogId" element={<BlogsDetail />} />
                                    <Route path="contact" element={<Contact />} />
                                    <Route path="faq" element={<Faq />} />
                                    <Route path="privacy" element={<Privacy />} />
                                    <Route path="conditions" element={<TermsAndConditions />} />
                                </Routes>
                            </Grid>
                        </Container>
                    </div>
                    <Footer />
                </div>
            </ScreenClassProvider>
        </BlogProvider>
    </DesignersProvider>
);

export default Layout;
