import {db} from "../FirebaseConfig";
import {collection, getDocs, query} from 'firebase/firestore';
import {FilterCriteria} from "../types/FilterCriteria";
import {Designer} from "../interfaces/Designer";

export const fetchDesigners = async (): Promise<Designer[]> => {
    const response = await getDocs(collection(db, 'designers'));
    return response.docs.map((doc) => doc.data() as Designer);
};

export const fetchFilterCriteriaData = async (): Promise<FilterCriteria> => {
    const professionsSet = new Set<string>();
    const stylesSet = new Set<string>();
    const specialisationsSet = new Set<string>();
    const locationsSet = new Set<string>();

    const q = query(collection(db, 'designers'));
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
        const data = doc.data();

        // For professions
        const professions = (data.profession as string[]) || [];
        professions.forEach(profession => professionsSet.add(profession))

        // For design styles
        const styles = (data.designStyles as string[]) || [];
        styles.forEach(style => stylesSet.add(style));

        // For specialisations
        const specialisation = (data.specialisations as string[]) || [];
        specialisation.forEach(spec => specialisationsSet.add(spec));

        // For locations
        const location = (data.location as string[]) || [];
        location.forEach(location => locationsSet.add(location))
    });

    return {
        designStyles: Array.from(stylesSet),
        profession: Array.from(professionsSet),
        specialisation: Array.from(specialisationsSet),
        location: Array.from(locationsSet),
        priceRange: [], // This needs to be handled based on your application's logic
    };
}
