import {DownloadUrl} from "./DownloadUrl";
import defaultProfilePic from "../imgs/LogoIcon.png";

// Define an interface for the object returned by srcset function
interface ImageSrcSet {
    src: string;
    srcSet: string;
}

export async function srcset(image: string, width: number, height: number, id: string, map: string, root: string): Promise<ImageSrcSet> {
    try {
        const imageUrl = await DownloadUrl(root, id, map, image);
        return {
            src: `${imageUrl}?alt=media&w=${width}&h=${height}&fit=crop&auto=format`,
            srcSet: `${imageUrl}?alt=media&w=${width}&h=${height}&fit=crop&auto=format 1x, ${imageUrl}?alt=media&w=${width * 2}&h=${height * 2}&fit=crop&auto=format 2x`
        };
    } catch (error) {
        console.error("Error fetching download URL:", error);
        // Return a default object if an error occurs
        return {
            src: `${defaultProfilePic}?alt=media&w=${width}&h=${height}&fit=crop&auto=format`,
            srcSet: `${defaultProfilePic}?alt=media&w=${width}&h=${height}&fit=crop&auto=format 1x, ${defaultProfilePic}?alt=media&w=${width * 2}&h=${height * 2}&fit=crop&auto=format 2x`
        };
    }
}


