import {Card, ImageList, ImageListItem, ImageListItemBar, Skeleton, Typography} from "@mui/material";
import {FeaturedProjectsProps} from "../../interfaces/FeaturedProjectsProps";
import {useState} from "react";

const FeaturedProjects = ({loading, featured, handleOnClick, widthCol}: FeaturedProjectsProps) => {
    const [imageLoading, setImageLoading] = useState<boolean[]>(Array(featured.length).fill(false));

    const handleImageLoaded = (index: number) => {
        const newImageLoading = [...imageLoading];
        newImageLoading[index] = true;
        setImageLoading(newImageLoading);
    };
    return (
        <>
            <Typography sx={{paddingTop: "4rem", paddingBottom: "4rem"}} variant="h5">UITGELICHTE PROJECTEN</Typography>
            <ImageList sx={{width: "100%", height: "auto", margin: "auto", paddingBottom: "2rem"}}
                       gap={12} cols={widthCol}>
                {loading ? (
                    Array.from({length: 6}).map((_, index) => (
                        <Skeleton key={index} variant="rectangular" width={"100%"} height={"30rem"}>
                            <ImageListItem/>
                        </Skeleton>
                    ))
                ) : (
                    featured.map((item, index) => (
                        <Card key={item.id} variant="outlined">
                            <ImageListItem key={item.id}>
                                <img src={item.src} srcSet={item.srcSet} alt={item.alt} loading="lazy"
                                     style={{
                                         width: '100%',
                                         height: '30rem',
                                         objectFit: 'cover',
                                     }}
                                     onLoad={() => handleImageLoaded(index)}
                                     onClick={() => handleOnClick(item.designerId, item.designer)}/>
                                <ImageListItemBar title={item.title} subtitle={item.designer} position="below"/>
                            </ImageListItem>
                        </Card>
                    ))
                )}
            </ImageList>
        </>
    );
}

export default FeaturedProjects;
