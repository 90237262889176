import React, {useEffect, useState} from 'react';
import {Box, Typography} from "@mui/material";
import {Designer} from "../../../interfaces/Designer";
import {DownloadUrl} from "../../../utils/DownloadUrl";
import {StyledCarousel} from "../../../theme/Carousel";

interface VideoInfo {
    url: string;
    name: string;
}

const VideoGallery = ({designer}: { designer: Designer }) => {
    const [videos, setVideos] = useState<VideoInfo[]>([]);

    useEffect(() => {
        const fetchVideos = async () => {
            const videoUrls = await Promise.all(
                Object.values(designer.projects).filter(project => project.project_vid).map(async (project) => {
                    // Ensure project.project_vid is not undefined or empty
                    if (project.project_vid) {
                        try {
                            const url = await DownloadUrl('designers', designer.designerId, 'videos', project.project_vid);
                            return {
                                url,
                                name: project.project_name
                            };
                        } catch (error) {
                            console.error("Error fetching video URL:", error);
                            return null; // Return null for failed fetches, to filter out later
                        }
                    }
                    return null; // Return null for projects without a project_url
                })
            );
            // Filter out any null entries and entries without valid URLs
            setVideos(videoUrls.filter((video): video is VideoInfo => video !== null && video.url !== undefined) as VideoInfo[]);

        };

        fetchVideos();
    }, [designer]);

    if (videos.length === 0) {
        return null
    }

    return (
        <Box sx={{mt: 4}}>
            <Typography variant="h5" sx={{mb: 2}}>
                Video Galerij
            </Typography>
            <StyledCarousel
                showArrows={true}
                swipeable={true}
                useKeyboardArrows={true}
                showThumbs={false}
                showStatus={false}
                showIndicators={true}
            >
                {videos.map((video, index) => (
                    <div key={index}
                         style={{width: '100%', overflow: 'hidden', position: 'relative', paddingBottom: '56.25%'}}>
                        <video
                            controls
                            title={`${video.name} Video`}
                            style={{width: '100%', height: '100%', position: 'absolute', top: 0, left: 0}}
                        >
                            <source src={video.url} type="video/mp4"/>
                            Your browser does not support the video tag.
                        </video>
                    </div>
                ))}
            </StyledCarousel>
        </Box>
    );
};

export default VideoGallery;
