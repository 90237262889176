import {useEffect, useState} from "react";
import {collection, onSnapshot, orderBy, query} from "firebase/firestore";
import {db} from "../FirebaseConfig";
import {Inspiration} from "../interfaces/Inspiration";

export function fetchInspirationsQuery(): [Inspiration[], boolean] {
    const [inspirationItems, setInspirationItems] = useState<Inspiration[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = onSnapshot(
            query(collection(db, 'inspiration'), orderBy('dateAdded', 'desc')),
            (snapshot) => {
                const fetchedInspirationItems: Inspiration[] = [];
                snapshot.forEach((doc) => {
                    const data = doc.data();
                    // Ensure data is transformed to match the Blog interface
                    fetchedInspirationItems.push({
                        inspirationId: doc.id,                    // Assuming Firestore document ID maps to blogId
                        ...data,
                    } as Inspiration);
                });
                setInspirationItems(fetchedInspirationItems);
                setLoading(false);
            }
        );

        return () => unsubscribe();
    }, []);

    return [inspirationItems, loading];
}
