import {getDownloadURL, getStorage, ref} from "firebase/storage";

export async function DownloadUrl(root: string, id: string, map: string, file: string, folder?: string): Promise<string> {
    const storage = getStorage();
    let referencePath = `${root}/${id}/${map}/${file}`;
    if (folder) {
        referencePath = `${root}/${id}/${map}/${folder}/${file}`;
    }
    const reference = ref(storage, referencePath);
    return await getDownloadURL(reference);
}
