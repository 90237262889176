import React from 'react';
import './App.css';
import '@fontsource/montserrat/400.css'
import '@fontsource/montserrat/300.css'
import {RouterProvider} from "react-router-dom";
import router from "././router/react-router";
import {ThemeProvider} from "@mui/material";
import {theme} from "./theme/Theme";

function App() {
    return (
        <ThemeProvider theme={theme}>
            <RouterProvider router={router}/>
        </ThemeProvider>
    );
}

export default App;
