import React from 'react';
import {createBrowserRouter} from "react-router-dom";
import Layout from "../components/Layout/Layout";
import Home from "../components/Home/Home";

const router = createBrowserRouter([
        {
            path: "*",
            element: <Layout/>,
            children: [
                {
                    path: "",
                    element: <Home/>
                }
            ]
        }
    ]
)
export default router;
