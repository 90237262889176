export const priceSegmentInfo = `
  <p>€ Budget of Instapniveau</p>
  <p>Dit niveau is bedoeld voor klanten met een beperkt budget die basisontwerpadviezen en -diensten nodig hebben. Tarieven kunnen variëren van €50 tot €100 per uur of een vast tarief per project dat kan beginnen vanaf enkele honderden euro's voor kleinere projecten.</p>
  <p>€€ Middenklasse</p>
  <p>Ontwerpers in deze categorie bieden een goede balans tussen kwaliteit en prijs en zijn geschikt voor de meeste standaard woon- en commerciële projecten. Uurtarieven kunnen liggen tussen €100 tot €200, met vaste projecttarieven die uiteenlopen van enkele duizenden euro's tot meer, afhankelijk van de projectomvang.</p>
  <p>€€€ Premium</p>
  <p>Deze categorie omvat ervaren ontwerpers met een uitgebreid portfolio die gespecialiseerd zijn in meer op maat gemaakte en high-end projecten. Uurtarieven voor premium diensten variëren van €200 tot €300 of meer, met vaste tarieven die vaak beginnen in de tienduizenden euro's voor omvangrijke projecten.</p>
  <p>€€€€ Luxe of High-End</p>
  <p>Topsegment ontwerpers die zich richten op luxe residentiële of commerciële projecten kunnen uurtarieven hanteren van €300 of meer, met projectkosten die gemakkelijk kunnen oplopen tot honderdduizenden euro's, afhankelijk van de specificaties en de omvang van het project.</p>
`;
