import React, {useEffect, useRef, useState} from 'react';
import {Card, Dialog, IconButton, Skeleton, Tooltip} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {ProjectGalleryProps} from "../../../interfaces/ProjectGalleryProps";
import {CustomArrowLeft, CustomArrowRight, StyledCarousel} from "../../../theme/Carousel";
import FullscreenIcon from '@mui/icons-material/Fullscreen';

type ObjectFit = 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';

const ProjectGallery = ({pictures, projectKey}: ProjectGalleryProps) => {
    const [open, setOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleOpen = (index: number) => {
        setSelectedIndex(index); // Set the selected index
        setOpen(true); // Open the carousel dialog
    };
    const handleClose = () => setOpen(false);

    const [objectFitStyle, setObjectFitStyle] = useState<ObjectFit>('contain');
    const imageRef = useRef<HTMLImageElement>(null);

    const updateObjectFitStyle = (img: HTMLImageElement) => {
        if (img.naturalWidth > img.naturalHeight) {
            setObjectFitStyle('cover');
        } else {
            setObjectFitStyle('contain');
        }
    };

    useEffect(() => {
        if (imageRef.current) {
            updateObjectFitStyle(imageRef.current);
        }
    }, [pictures]);

    return (
        <>
            {pictures.length > 0 ? (
                <>
                    <Card
                        sx={{
                            cursor: 'pointer',
                            margin: 'auto',
                            position: 'relative',
                            overflow: 'hidden',
                            display: 'block', // Default to block for small screens
                            maxWidth: 'none', // Allow the card to take full width on small screens
                            // Apply styles specific to medium screens and up
                            '@media (min-width:900px)': {
                                display: 'inline-block', // Shrink to fit the content
                                maxWidth: 'fit-content', // Restrict width to the content size
                                width: 'auto', // Adjust width to content
                            },
                        }}
                    >
                        <StyledCarousel
                            showArrows={true}
                            swipeable={true}
                            useKeyboardArrows={true}
                            showThumbs={false}
                            showStatus={false}
                            showIndicators={false}
                            dynamicHeight={true}
                            emulateTouch={true}
                            infiniteLoop={true}
                            onClickItem={(index) => handleOpen(index)}
                            renderArrowPrev={(onClickHandler, hasPrev, label) =>
                                hasPrev && (
                                    <CustomArrowLeft onClick={onClickHandler} className="custom-arrow custom-arrow-left"
                                                     aria-label="Previous"/>
                                )
                            }
                            renderArrowNext={(onClickHandler, hasNext, label) =>
                                hasNext && (
                                    <CustomArrowRight onClick={onClickHandler} className="custom-arrow custom-arrow-right"
                                                      aria-label="Next"/>
                                )
                            }
                        >

                            {pictures.map((src, index) => {
                                const [imageLoaded, setImageLoaded] = useState(false);

                                return (
                                    <div key={index}>
                                        {!imageLoaded && (
                                            <Skeleton variant="rectangular" width="100%" height={"40rem"}/>
                                        )}
                                        <img
                                            ref={imageRef}
                                            src={src}
                                            alt={`${projectKey} Picture ${index + 1}`}
                                            onLoad={() => {
                                                setImageLoaded(true);
                                                if (imageRef.current) {
                                                    updateObjectFitStyle(imageRef.current);
                                                }
                                            }}
                                            style={{
                                                maxHeight: '40rem',
                                                width: '100%',
                                                objectFit: "cover",
                                                display: imageLoaded ? 'block' : 'none'
                                            }}
                                        />
                                    </div>
                                );
                            })}
                        </StyledCarousel>
                        <div
                            style={{
                                position: 'absolute',
                                bottom: '16px',
                                right: '16px',
                                zIndex: 1,
                            }}
                        >
                            <Tooltip title="Fullscreen">
                                <IconButton onClick={() => handleOpen(selectedIndex)}>
                                    <FullscreenIcon fontSize='large' style={{color: 'white'}}/>
                                </IconButton>
                            </Tooltip>
                        </div>
                    </Card>
                </>
            ) : (
                <Skeleton variant="rectangular" width="100%" height={"40rem"}/>
            )}

            {open && (
                <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth
                        sx={{
                            '& .MuiDialog-paper': {
                                m: 0,
                                maxWidth: '100%',
                                maxHeight: '100%',
                                backgroundColor: 'rgba(0, 0, 0, 0)'
                            }
                        }}>
                    <IconButton onClick={handleClose}
                                sx={{position: 'absolute', right: 8, top: 8, color: 'white', zIndex: 1}}>
                        <CloseIcon/>
                    </IconButton>
                    <StyledCarousel
                        showArrows={true}
                        swipeable={true}
                        useKeyboardArrows={true}
                        showThumbs={false}
                        showStatus={false}
                        showIndicators={false}
                        dynamicHeight={true}
                        emulateTouch={true}
                        infiniteLoop={true}
                        selectedItem={selectedIndex}
                        renderArrowPrev={(onClickHandler, hasPrev, label) =>
                            hasPrev && (
                                <CustomArrowLeft onClick={onClickHandler} className="custom-arrow custom-arrow-left"
                                                 aria-label="Previous"/>
                            )
                        }
                        renderArrowNext={(onClickHandler, hasNext, label) =>
                            hasNext && (
                                <CustomArrowRight onClick={onClickHandler} className="custom-arrow custom-arrow-right"
                                                  aria-label="Next"/>
                            )
                        }
                    >
                        {pictures.map((src, index) => (
                            <div key={index} style={{}}>
                                <img
                                    ref={imageRef}
                                    src={src}
                                    alt={`${projectKey} Picture ${index + 1}`}
                                    onLoad={() => {
                                        if (imageRef.current) {
                                            updateObjectFitStyle(imageRef.current);
                                        }
                                    }}
                                    style={{
                                        maxHeight: '90vh',
                                        width: '100%',
                                        objectFit: objectFitStyle
                                    }}
                                />
                            </div>
                        ))}
                    </StyledCarousel>
                </Dialog>
            )}
        </>
    );
};

export default ProjectGallery;
