import React, { useContext, useMemo, useEffect, ReactNode } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { DesignersContext } from '../../context/DesignersContext';

import { logEvent } from 'firebase/analytics';
import {analytics} from "../../FirebaseConfig";

interface ScreenClassProviderProps {
    children: ReactNode;
}

const ScreenClassProvider: React.FC<ScreenClassProviderProps> = ({ children }) => {
    const location = useLocation();
    const { designerId } = useParams<{ designerId: string }>();
    const { designersWithImages } = useContext(DesignersContext);

    const designer = useMemo(() => {
        if (!designerId) return null;
        return designersWithImages.find(d => d.designerId === designerId);
    }, [designersWithImages, designerId]);

    const getScreenClass = () => {
        const path = location.pathname;

        if (path === '/designers') return 'screen-designers-overview';
        if (path.match(/^\/designers\/[^/]+$/)) return designer ? `screen-designer-${designer.businessName}` : 'screen-designer-detail';
        if (path === '/inspiration') return 'screen-inspiration';
        if (path === '/blogs') return 'screen-blogs-overview';
        if (path.match(/^\/blogs\/[^/]+$/)) return 'screen-blogs-detail';
        if (path === '/contact') return 'screen-contact';
        if (path === '/faq') return 'screen-faq';
        if (path === '/privacy') return 'screen-privacy';
        if (path === '/conditions') return 'screen-terms-conditions';
        return 'screen-home';
    };

    const screenClass = getScreenClass();

    useEffect(() => {
        // Log pageview event to Firebase Analytics
        logEvent(analytics, 'page_view', {
            page_path: location.pathname + location.search,
            page_title: document.title,
            page_location: window.location.href,
            screen_class: screenClass,
        });
    }, [location, screenClass]);

    return <div className={screenClass}>{children}</div>;
};

export default ScreenClassProvider;
