import React, {useCallback, useContext, useEffect, useState} from 'react';
import {
    Card,
    Grid,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    Skeleton,
    Typography,
    useMediaQuery
} from "@mui/material";

import defaultProfilePic from '../../../imgs/LogoIcon.png';
import {theme} from "../../../theme/Theme";
import {useNavigate} from "react-router-dom";
import {BlogContext} from "../../../context/BlogContext";
import {logEvent} from "firebase/analytics";
import {analytics} from "../../../FirebaseConfig";
import useScrollMemory from "../../../utils/useScrollMemory";
import {DownloadUrl} from "../../../utils/DownloadUrl";

const BlogsOverview = () => {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const {blogs: initialBlogs, loading: blogsLoading} = useContext(BlogContext);
    const [blogs, setBlogs] = useState(initialBlogs); // State to store blogs with updated thumbnail URLs

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const blogWidthCol = isMobile ? 1 : 2;

    useEffect(() => {
        setLoading(blogsLoading);
    }, [blogsLoading]);

    useEffect(() => {
        const fetchThumbnails = async () => {
            try {
                // Fetch and update thumbnail URLs for each blog
                const updatedBlogs = await Promise.all(initialBlogs.map(async (blog) => {
                    try {
                        const thumbnailUrl = await DownloadUrl("blogs", blog.blogId, "", blog.thumbnailImg);
                        return {...blog, thumbnailImg: thumbnailUrl}; // Update the thumbnailImg property
                    } catch (error) {
                        console.error("Error fetching thumbnail URL:", error);
                        // If fetching thumbnail fails, return the original blog
                        return blog;
                    }
                }));

                // Update blogs state with the fetched data
                setBlogs(updatedBlogs);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching thumbnails:", error);
                setLoading(false);
            }
        };

        fetchThumbnails();
    }, [initialBlogs]);

    const saveScrollPosition = useScrollMemory('/blogs');

    const blogClick = useCallback((blogId: string, blogTitle: string) => {
        saveScrollPosition();
        logEvent(analytics, 'blogs_overview_blog_click', {'overview_blog_name': blogTitle});
        navigate(`/blogs/${blogId}`);
    }, [navigate]);

    function formatDate(date: Date): string {
        const options: Intl.DateTimeFormatOptions = {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        };
        return date.toLocaleDateString('nl-NL', options);
    }


    return (
        <>
            <Grid item xs={12}>
                <Typography variant="h4" align="center" sx={{mb: 4}}>Blogs</Typography>
            </Grid>
            <ImageList sx={{
                width: "100%", height: "auto", margin: "auto", pb: 2, justifyContent: "center",
                textAlign: "center", marginLeft: '1rem'
            }} gap={12} cols={blogWidthCol}>
                {loading ? (
                    Array.from(new Array(blogWidthCol)).map((_, index) => (
                        <Skeleton key={index} variant="rectangular" width="22rem" height="18.75rem"/>
                    ))
                ) : (
                    blogs
                        .slice()
                        .sort((a, b) => new Date(b.dateAdded).getTime() - new Date(a.dateAdded).getTime())
                        .map((item) => (
                        <Card key={item.blogId} variant="outlined"
                              onClick={() => blogClick(item.blogId, item.blogTitle)}>
                            <ImageListItem style={{width: '100%'}}>
                                <img
                                    src={item.thumbnailImg || defaultProfilePic}
                                    alt={item.blogTitle || 'Geen titel beschikbaar'}
                                    loading="lazy"
                                    style={{
                                        width: '100%',
                                        height: 'auto',
                                        maxHeight: '25rem',
                                        display: 'block',
                                        margin: 'auto'
                                    }}
                                />
                                <ImageListItemBar
                                    title={item.blogTitle}
                                    subtitle={formatDate(new Date(item.dateAdded))}
                                    position="below"
                                    sx={{
                                        textOverflow: 'clip',
                                        whiteSpace: 'normal',
                                        overflow: 'visible',
                                        height: 'auto'
                                    }}
                                />
                            </ImageListItem>
                        </Card>
                    ))
                )}
            </ImageList>
        </>
    );
}

export default BlogsOverview;
