import React, { createContext, ReactNode, useEffect, useState } from 'react';
import { fetchDesigners } from '../repositories/DesignerRepository';
import { DownloadUrl } from '../utils/DownloadUrl';
import defaultProfilePic from '../imgs/LogoIcon.png';
import { Designer } from '../interfaces/Designer';
import { fetchProjectPictures } from "../repositories/ProjectPicturesRepository";
import CryptoJS from 'crypto-js';

interface DesignersContextType {
    designersWithImages: Designer[];
}

const defaultContextValue: DesignersContextType = {
    designersWithImages: [],
};

export const DesignersContext = createContext<DesignersContextType>(defaultContextValue);

interface DesignersProviderProps {
    children: ReactNode;
}

const secretKey = "your-secret-key"; // Replace with your actual secret key

export const DesignersProvider = ({ children }: DesignersProviderProps) => {
    const [designersWithImages, setDesignersWithImages] = useState<Designer[]>(() => {
        // Attempt to load and decrypt designers from local storage
        const savedDesigners = localStorage.getItem('designersWithImages');
        if (savedDesigners) {
            try {
                const bytes = CryptoJS.AES.decrypt(savedDesigners, secretKey);
                const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
                if (decryptedData) {
                    return JSON.parse(decryptedData);
                }
            } catch (error) {
                console.error('Error decrypting designers:', error);
            }
        }
        return [];
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const fetchedDesigners = await fetchDesigners();

                const designersWithProcessedImages = await Promise.all(
                    fetchedDesigners.map(async (designer) => {
                        const thumbnailUrl = await fetchImage('thumbnail', designer.thumbnail, designer.designerId);
                        const profileUrl = await fetchImage('profile_picture', designer.profilePicture, designer.designerId);
                        const projectPictures = await fetchProjectPicturesForDesigner(designer);

                        return { ...designer, thumbnail: thumbnailUrl, profilePicture: profileUrl, projectPictures };
                    })
                );

                setDesignersWithImages(designersWithProcessedImages);
                // Encrypt and save updated data to localStorage
                const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(designersWithProcessedImages), secretKey).toString();
                localStorage.setItem('designersWithImages', ciphertext);
            } catch (error) {
                console.error('Error fetching designers:', error);
            }
        };

        fetchData();
    }, []);

    async function fetchImage(imageType: string, imageName: string, designerId: string) {
        try {
            if (imageName) {
                return await DownloadUrl('designers', designerId, imageType, imageName);
            }
        } catch (error) {
            console.error(`Error fetching ${imageType}:`, error);
        }
        return defaultProfilePic;
    }

    async function fetchProjectPicturesForDesigner(designer: Designer) {
        const projectPictures: Record<string, string[]> = {};

        for (const projectId in designer.projects) {
            if (designer.projects.hasOwnProperty(projectId)) {
                projectPictures[projectId] = await fetchProjectPictures(designer, projectId);
            }
        }

        return projectPictures;
    }

    return <DesignersContext.Provider value={{ designersWithImages }}>{children}</DesignersContext.Provider>;
};
