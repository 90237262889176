import React, {ChangeEvent, useContext, useEffect, useMemo, useState} from 'react';
import {Box, Card, CardContent, CardMedia, Chip, Grid, Link, Pagination, Skeleton, Typography} from '@mui/material';
import {FilterCriteria} from "../../../types/FilterCriteria";
import {DarkGray, Gold, OffWhite} from "../../../theme/Colors";
import {logEvent} from "firebase/analytics";
import {analytics} from "../../../FirebaseConfig";
import defaultProfilePic from '../../../imgs/LogoIcon.png';
import {useNavigate} from "react-router-dom";
import {DesignersContext} from "../../../context/DesignersContext";
import useScrollMemory from "../../../utils/useScrollMemory";
import FilterDrawer from "../Filter/FilterDrawer";

interface ImageLoadingState {
    [key: string]: boolean;  // Type definition for the imageLoading state
}

const DesignersOverview = () => {
    const [filterCriteria, setFilterCriteria] = useState<FilterCriteria>({
        designStyles: [],
        profession: [],
        location: [],
        specialisation: [],
        priceRange: [],
    });
    const initialFilterCriteria: FilterCriteria = {
        profession: [],
        designStyles: [],
        location: [],
        specialisation: [],
        priceRange: [],
    };
    const {designersWithImages} = useContext(DesignersContext);

    const filteredData = useMemo(() => designersWithImages.filter(designer => {
        const matchesDesignStyles = !filterCriteria.designStyles.length || filterCriteria.designStyles.some(style => designer.designStyles.includes(style));
        const matchesDesignCategory = !filterCriteria.profession.length || filterCriteria.profession.some((prof) => designer.profession.includes(prof));
        const matchesPriceRange = filterCriteria.priceRange.length === 0 || filterCriteria.priceRange.includes(designer.priceRange);
        const matchesLocation = filterCriteria.location.length === 0|| filterCriteria.location.some((loc) => designer.location.includes(loc));
        const matchesSpecialisation = filterCriteria.specialisation.length === 0 || filterCriteria.specialisation.some(spec => designer.specialisations.includes(spec));
        return matchesDesignStyles && matchesDesignCategory && matchesPriceRange && matchesLocation && matchesSpecialisation;
    }), [filterCriteria, designersWithImages]);

    const itemsPerPage = 12;
    const [page, setPage] = useState(1);
    const noOfPages = Math.ceil(filteredData.length / itemsPerPage);
    const [loading, setLoading] = useState(true);

    const handlePageChange = (_event: ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const handleStyleChipClick = (style: string) => {
        setFilterCriteria({...initialFilterCriteria, designStyles: [style]});
    };

    const clearAllFilter = () => {
        setFilterCriteria({
            ...initialFilterCriteria
        });
    };
    const navigate = useNavigate();
    const saveScrollPosition = useScrollMemory('/designers');

    const handleOnClick = (designerId: string, designerName: string) => {
        saveScrollPosition();
        logEvent(analytics, 'designer_overview_click', {'overview_designer_name': designerName})
        navigate(`/designers/${designerId}`);
    };
    useEffect(() => {
        if (!designersWithImages || designersWithImages.length === 0) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [designersWithImages]); // Depend on the actual data source

    const [imageLoading, setImageLoading] = useState<ImageLoadingState>(
        Object.fromEntries(
            designersWithImages.map(designer => [designer.designerId, false])
        )
    );

    const handleImageLoaded = (designerId: string) => {
        setImageLoading((prevState) => ({
            ...prevState,
            [designerId]: true
        }));
    };

    return (
        <>
            <Grid item xs={12}>
                <Box display="flex" justifyContent="center" marginBottom="1rem">
                    <Typography variant="h4" align="center">Ontwerpers</Typography>
                </Box>
            </Grid>
            <Grid item xs={12} md={3} className="filterGrid">
                <FilterDrawer
                    filterCriteria={filterCriteria}
                    setFilterCriteria={setFilterCriteria}
                    clearAllFilter={clearAllFilter}
                />
            </Grid>
            <Grid item xs={12} md={9} className="resultsGrid">
                {loading ? (
                        Array.from({length: 12}).map((_, index) => (
                            <div key={index}>
                                <Skeleton
                                    variant="rectangular"
                                    width={"100%"}
                                >
                                    <Card sx={{height: {xs: "40rem", md: "25rem"}}}/>
                                </Skeleton>
                            </div>
                        ))
                    ) :
                    filteredData.length > 0 ? (
                        filteredData.slice((page - 1) * itemsPerPage, page * itemsPerPage).map(designer => (
                                <Card key={designer.designerId} variant="outlined"
                                      sx={{
                                          marginBottom: 2,
                                          backgroundColor: OffWhite,
                                          height: {xs: "40rem", md: "25rem"},
                                          cursor: 'pointer'
                                      }} onClick={() => handleOnClick(designer.designerId, designer.fullName)}>
                                    <Grid container>
                                        <Grid item xs={12} md={5}>
                                            <CardContent>
                                                <Typography variant="h5"
                                                            color={Gold}>{designer.bundle === 'free' ? `Ontwerper in ${designer.location}` : designer.businessName}</Typography>
                                                <Typography variant="h6"
                                                            color={DarkGray}>{Array.isArray(designer.profession)
                                                    ? designer.profession.map((prof, index) => (
                                                        <><span key={index}>{prof}</span><br/></>
                                                    ))
                                                    : designer.profession}</Typography>
                                                <Box display="flex" gap={1} flexWrap="wrap">
                                                    {designer.designStyles.map((style, index) => (
                                                        <Chip key={index} label={style} variant="outlined" size="small"
                                                              onClick={(event) => {
                                                                  event.stopPropagation(); // Stop the event from bubbling up to the card
                                                                  handleStyleChipClick(style);
                                                              }} clickable/>
                                                    ))}
                                                </Box>
                                                <Link component="button" sx={{marginTop: "3rem", marginLeft: "2rem"}}>Zie
                                                    meer</Link><br/>
                                            </CardContent>
                                        </Grid>
                                        <Grid item xs={12} md={7}>
                                            {!imageLoading[designer.designerId] && (
                                                <Skeleton variant="rectangular" width="100%"
                                                          sx={{height: {xs: "40rem", md: "25rem"}}}/>
                                            )}
                                            <CardMedia
                                                component="img"
                                                sx={{
                                                    height: {xs: "40rem", md: "25rem"}, // Responsive height
                                                    objectFit: "cover",
                                                }}
                                                image={designer.thumbnail || defaultProfilePic}
                                                alt="Designer profile"
                                                onLoad={() => handleImageLoaded(designer.designerId)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Card>
                            )
                        )
                    ) : (
                        <Typography variant="h6">Er zijn helaas geen ontwerpers gevonden</Typography>
                    )
                }
            </Grid>
            <Grid item xs={12}>
                {filteredData.length > 12 && (
                    <Box display="flex" justifyContent="center" marginTop="2rem">
                        <Pagination count={noOfPages} page={page} onChange={handlePageChange}/>
                    </Box>
                )}
            </Grid>
        </>
    );
};

export default DesignersOverview;
