import {NavLink} from "react-router-dom";
import './Navbar.css';
import Grid from "@mui/material/Grid";
import {Box, SwipeableDrawer, Typography, useMediaQuery, useTheme} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import {useMemo, useState} from "react";
import {Helmet} from "react-helmet";

const Navbar = () => {
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen);
    };

    const navLinks = useMemo(() => ([
        {label: 'HOME', path: '/'},
        {label: 'ONTWERPERS', path: '/designers'},
        {label: 'INSPIRATIE', path: '/inspiration'},
        {label: 'BLOG', path: '/blogs'},
    ]), []);

    const logoSrc = require('../../imgs/Logo.webp');

    const drawerList = (
        <Box role="presentation" onClick={toggleDrawer(false)}>
            <Grid container className="navLinks" spacing={2}>
                <Grid item xs={12}>
                    <NavLink to="/">
                        <div className="logoContainer">
                            <img src={require('../../imgs/LogoIcon.png')} alt="logo"/>
                        </div>
                    </NavLink>
                </Grid>
                {navLinks.map((link) => (
                    <Grid item xs={12} key={link.label}>
                        <NavLink to={link.path}><Typography
                            sx={{paddingTop: "3rem"}}>{link.label}</Typography></NavLink>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );

    const desktopNavLinks = (
        <Grid container className="navLinks" spacing={2}>
            {navLinks.map((link) => (
                <Grid item md={3} key={link.label}>
                    <NavLink to={link.path}><Typography>{link.label}</Typography></NavLink>
                </Grid>
            ))}
        </Grid>
    );

    return (
        <Box sx={{flexGrow: 1}} className="navbar">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <NavLink to="/">
                        <div className="logoContainer">
                            <Helmet>
                                <link rel="preload" href={logoSrc} as="image"/>
                            </Helmet>
                            <img src={logoSrc} alt="logo"/>
                        </div>
                    </NavLink>
                </Grid>
                {!isMobile ? desktopNavLinks : (
                    <>
                        <Grid item xs={12} container sx={{justifyContent: "flex-end"}}>
                            <MenuIcon fontSize="large" className="menuIcon" onClick={toggleDrawer(true)}/>
                        </Grid>
                        <SwipeableDrawer
                            open={open}
                            onClose={toggleDrawer(false)}
                            anchor='right'
                            sx={{
                                '& .MuiDrawer-paper': {maxWidth: '70%', width: '100%'},
                            }}
                            disableSwipeToOpen={false}
                            onOpen={() => {
                                setOpen(true)
                            }}
                        >
                            {drawerList}
                        </SwipeableDrawer>
                    </>
                )}
            </Grid>
        </Box>
    );
}

export default Navbar;
