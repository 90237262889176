import React, {createContext, ReactNode} from 'react';
import {fetchBlogsQuery} from '../repositories/BlogRepository'; // Import the custom hook
import {Blog} from '../interfaces/Blog'; // Assuming Blog has the same structure as Blogs

// Define the type for the context data
interface BlogContextType {
    blogs: Blog[];
    loading: boolean;
}

// Define a default value for the context
const defaultBlogContext: BlogContextType = {
    blogs: [],
    loading: true,
};

// Create the context with the default value
export const BlogContext = createContext<BlogContextType>(defaultBlogContext);

// Define the type for the provider component props
interface BlogProviderProps {
    children: ReactNode;
}

// Create the provider component
export const BlogProvider = ({children}: BlogProviderProps) => {
    // Use the custom hook at the top level of your component
    const [blogs, loading] = fetchBlogsQuery();

    // Provider component wraps children with the BlogContext provider
    return (
        <BlogContext.Provider value={{blogs, loading}}>
            {children}
        </BlogContext.Provider>
    );
};
