import React, {useState} from 'react';
import {Box, Button, Fab, IconButton, SwipeableDrawer, Typography, useMediaQuery, useTheme} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DesignerFilter from './Filter';
import TuneIcon from '@mui/icons-material/Tune';
import DeleteIcon from '@mui/icons-material/Delete';

interface FilterDrawerProps {
    filterCriteria: any;
    setFilterCriteria: (criteria: any) => void;
    clearAllFilter: () => void;
}

const FilterDrawer: React.FC<FilterDrawerProps> = ({filterCriteria, setFilterCriteria, clearAllFilter}) => {
    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = (newOpen: boolean) => () => {
        setDrawerOpen(newOpen);
    };

    const drawerContent = (
        <>
            {!isMdUp && (
                <>
                    <Box display="flex" justifyContent="space-between" alignItems="center" padding="1rem">
                        <Typography variant="h6">Verfijn resultaten</Typography>
                        <IconButton onClick={toggleDrawer(false)}>
                            <CloseIcon/>
                        </IconButton>

                    </Box>
                </>
            )}
            <DesignerFilter filterCriteria={filterCriteria} setFilterCriteria={setFilterCriteria}/>
            <Box display="flex" justifyContent="center" width="100%" marginTop="1rem">
                <Button variant="outlined" onClick={clearAllFilter}><DeleteIcon/>Wis alle filters</Button>
            </Box>
        </>
    );

    return (
        <>
            {isMdUp ? (
                <>
                    {drawerContent}
                </>
            ) : (
                <>
                    <Fab
                        color="secondary"
                        aria-label="filters"

                        onClick={toggleDrawer(true)}
                        sx={{
                            position: 'fixed',
                            top: theme.spacing(16),
                            left: theme.spacing(2),
                            zIndex: 1000,
                        }}
                    >
                        <TuneIcon />
                    </Fab>
                    <SwipeableDrawer
                        anchor="left"
                        open={drawerOpen}
                        sx={{
                            '& .MuiDrawer-paper': {maxWidth: '70%', width: '100%'},
                        }}
                        disableSwipeToOpen={false}
                        onClose={toggleDrawer(false)}
                        onOpen={toggleDrawer(true)}
                    >
                        {drawerContent}
                    </SwipeableDrawer>
                </>
            )}
        </>
    );
};

export default FilterDrawer;
