import React, {useEffect, useState} from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Checkbox,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    IconButton,
    Tooltip,
    Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {FilterCriteria} from '../../../types/FilterCriteria';
import {DesignerFilterProps} from "../../../interfaces/DesignerFilterProps";
import {filterSections} from "./FilterSections";
import InfoIcon from '@mui/icons-material/Info';
import {priceRangeLabels} from "../../../utils/PriceRangeLabels";
import CloseIcon from "@mui/icons-material/Close";
import {priceSegmentInfo} from "../../../content/priceSegmentInfo";
import {fetchFilterCriteriaData} from "../../../repositories/DesignerRepository";

const DesignerFilter = ({filterCriteria, setFilterCriteria}: DesignerFilterProps) => {
    const [filters, setFilters] = useState<FilterCriteria
    >({
        profession: [],
        designStyles: [],
        location: [],
        specialisation: [],
        priceRange: ['budget', 'average', 'luxe', 'high'], // Assuming these are your internal values
    });

    useEffect(() => {
        const loadFilters = async () => {
            // Using the consolidated fetch function
            const filterData = await fetchFilterCriteriaData();

            setFilters(prev => ({
                ...prev,
                ...filterData,
                // Assuming priceRange needs to be handled separately or is static
                priceRange: ['budget', 'average', 'luxe', 'high'],
            }));
        };

        loadFilters();
    }, []);

    const handleCheckboxChange = (filterType: keyof FilterCriteria) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, checked} = event.target;
        setFilterCriteria((prevCriteria: FilterCriteria) => { // Explicitly type prevCriteria as FilterCriteria
            const updatedArray = checked
                ? [...prevCriteria[filterType], name]
                : prevCriteria[filterType].filter(value => value !== name);

            return {...prevCriteria, [filterType]: updatedArray};
        });
    };
    const [expanded] = useState<string | false>(filterSections[0].key);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const handleDialogOpen = () => {
        setIsDialogOpen(true);
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
    };

    return (
        <Box>
            {filterSections.map(({label, key}) => (
                <Accordion key={key}
                           defaultExpanded={expanded === key}
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        <Box sx={{display: 'flex', alignItems: 'center', width: '100%'}}>
                            <Typography variant="h6" sx={{flex: 1}}>{label}</Typography>
                            {key === 'priceRange' && (
                                <Tooltip title="Informatie over prijssegmenten">
                                    <IconButton size="medium"
                                                onClick={(event) => {
                                                    // Prevent the accordion action
                                                    event.stopPropagation();
                                                    handleDialogOpen(); // Open the dialog
                                                }}>
                                        <InfoIcon/>
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FormGroup>
                            {filters[key].map(value => (
                                <FormControlLabel
                                    key={value}
                                    control={
                                        <Checkbox
                                            checked={filterCriteria[key]?.includes(value)}
                                            onChange={handleCheckboxChange(key)}
                                            name={value}
                                        />
                                    }
                                    label={key === 'priceRange' && true ? (priceRangeLabels as any)[value] : value}
                                />
                            ))}
                        </FormGroup>
                    </AccordionDetails>
                </Accordion>
            ))}
            <Dialog
                open={isDialogOpen}
                onClose={handleDialogClose}
                aria-labelledby="prijssegmenten-dialog-title"
                aria-describedby="prijssegmenten-dialog-description"
            >
                <DialogTitle id="prijssegmenten-dialog-title">Prijs Segmenten</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleDialogClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
                <DialogContent>
                    <DialogContentText id="prijssegmenten-dialog-description"
                                       dangerouslySetInnerHTML={{__html: priceSegmentInfo}}/>
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default DesignerFilter;
