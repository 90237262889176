import {styled} from "@mui/material";
import {Carousel} from "react-responsive-carousel";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {DarkGray, Gold} from "./Colors";

export const StyledCarousel = styled(Carousel)(() => ({
    '.carousel .control-arrow, .carousel.carousel-slider .control-arrow': {
        display: 'none', // Hide default arrows
    },
    '.carousel-root': {
        position: 'relative',
        touchAction: 'pan-y',
    },
    '.carousel .slide': {
        position: 'relative', // Ensure the slides are positioned relatively
    },
    '.carousel .thumbs-wrapper': {
        margin: 0,
    },

    // Define CustomArrow styles
    '.custom-arrow': {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        borderRadius: '50%',
        width: '40px',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        zIndex: 1, // Ensure arrows are above the slides
    },
    '.custom-arrow-left': {
        color: 'white',
        left: 10, // Adjust the left position for the previous arrow
    },
    '.custom-arrow-right': {
        color: 'white',
        right: 10, // Adjust the right position for the next arrow
    },
    '.custom-arrow:hover': {
        backgroundColor: DarkGray, // Slight background color on hover
    },
}));

// Define CustomArrow components
export const CustomArrowLeft = styled(KeyboardArrowLeftIcon)({
    backgroundColor: Gold,
    color: 'white',
});

export const CustomArrowRight = styled(KeyboardArrowRightIcon)({
    backgroundColor: Gold,
    color: 'white',
});
