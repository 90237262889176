import {Card, CardContent, CardMedia, Link} from "@mui/material";
import Grid from "@mui/material/Grid";
import EastIcon from '@mui/icons-material/East';

interface HomeInspirationProps {
    randomImageUrl: string;
    mobileMargin: string;
    navigate: (path: string) => void;
}

const HomeInspiration = ({randomImageUrl, mobileMargin, navigate}: HomeInspirationProps) => {
    return (
        <Card variant="outlined">
            <Grid container>
                <Grid item xs={12} md={5}>
                    <CardMedia component="img" image={randomImageUrl} alt="Designer profile" loading="lazy"
                               sx={{width: '100%', height: 'auto', maxHeight: '50rem'}}/>
                </Grid>
                <Grid item xs={12} md={7}>
                    <CardContent>
                        <Link
                            component="button"
                            underline="hover"
                            sx={{
                                marginTop: mobileMargin,
                                marginBottom: "2rem",
                                fontSize: "1.5rem",
                            }} onClick={() => {
                            navigate(`/inspiration`);
                        }}>
                            Doe meer inspiratie op<br/><EastIcon fontSize="large"/>
                        </Link>
                    </CardContent>

                </Grid>
            </Grid>
        </Card>
    );
}

export default HomeInspiration;
