import React, {useEffect, useState} from 'react';
import {Button, Checkbox, FormControlLabel, Grid, Paper, Typography} from '@mui/material';
import './CookieConsentBanner.css';

interface Consent {
    necessary: boolean;
    analytics: boolean;
    preferences: boolean;
    marketing: boolean;
}


const CookieConsentBanner = () => {
    const [analyticsChecked, setAnalyticsChecked] = useState(true);
    const [preferencesChecked, setPreferencesChecked] = useState(true);
    const [marketingChecked, setMarketingChecked] = useState(false);
    const [bannerVisible, setBannerVisible] = useState(true);

    useEffect(() => {
        const consentStored = localStorage.getItem('consentMode') !== null;
        setBannerVisible(!consentStored);
    }, []);

    const setConsent = (consent: Consent) => {
        const consentMode = {
            ad_storage: consent.marketing ? 'granted' : 'denied',
            analytics_storage: consent.analytics ? 'granted' : 'denied',
            personalization_storage: consent.preferences ? 'granted' : 'denied',
            functionality_storage: consent.necessary ? 'granted' : 'denied',
            security_storage: consent.necessary ? 'granted' : 'denied',
            ad_user_data: consent.marketing ? 'granted' : 'denied',
            ad_personalization: consent.marketing ? 'granted' : 'denied'
        };
        const myWindow: Window = window;
        if (typeof myWindow.gtag === 'function') {
            myWindow.gtag('consent', 'update', consentMode);
        }
        localStorage.setItem('consentMode', JSON.stringify(consentMode));
        setBannerVisible(false);
    };

    const handleAcceptAll = () => {
        setConsent({
            necessary: true,
            analytics: true,
            preferences: true,
            marketing: true,
        });
    };

    const handleAcceptSelection = () => {
        setConsent({
            necessary: true,
            analytics: analyticsChecked,
            preferences: preferencesChecked,
            marketing: marketingChecked,
        });
    };

    const handleMarketingChange = (event: React.ChangeEvent<HTMLInputElement>) => setMarketingChecked(event.target.checked);
    const handleAnalyticsChange = (event: React.ChangeEvent<HTMLInputElement>) => setAnalyticsChecked(event.target.checked);
    const handlePreferencesChange = (event: React.ChangeEvent<HTMLInputElement>) => setPreferencesChecked(event.target.checked);

    return bannerVisible ? (
        <Paper id="cookie-consent-banner" className="cookie-consent-banner" elevation={3}>
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12}>
                    <Typography variant="h6" align="center">Cookie Instellingen</Typography>
                    <Typography paragraph align="center">
                        We gebruiken cookies om de beste ervaring te bieden en gebruikersgedrag te analyseren om onze site te verbeteren.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={2}>
                    <Button id="btn-accept-all" className="cookie-consent-button" variant="outlined"
                            onClick={handleAcceptAll} fullWidth size="small">
                        Accepteer alles
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} lg={2}>
                    <Button id="btn-accept-selection" className="cookie-consent-button" variant="outlined"
                            onClick={handleAcceptSelection} fullWidth size="small">
                        Accepteer geselecteerde opties
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2} className="cookie-consent-options">
                        <Grid item xs={6} lg={6}>
                            <FormControlLabel
                                control={<Checkbox id="consent-necessary" disabled checked/>}
                                label="Noodzakelijk"
                            />
                            <FormControlLabel
                                control={<Checkbox id="consent-analytics" checked={analyticsChecked}
                                                   onChange={handleAnalyticsChange}/>}
                                label="Analytics"
                            />
                            <FormControlLabel
                                control={<Checkbox id="consent-preferences" checked={preferencesChecked}
                                                   onChange={handlePreferencesChange}/>}
                                label="Preferenties"
                            />
                            <FormControlLabel
                                control={<Checkbox id="consent-marketing" checked={marketingChecked}
                                                   onChange={handleMarketingChange} color="primary"/>}
                                label="Marketing"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    ) : null;
};

export default CookieConsentBanner;
