import {FeaturedProject} from '../interfaces/FeaturedProject';
import {srcset} from "./ImageListSrcSetter";
import {Inspiration} from "../interfaces/Inspiration";
import {fetchInspirationPictures} from "../repositories/InspirationPicturesRepository";
import {DownloadUrl} from "./DownloadUrl";
import defaultProfilePic from '../imgs/LogoIcon.png';


export const processFeaturedProjectItems = async (featuredProjectItems: any[]) => {
    return await Promise.all(featuredProjectItems.map(async (item) => {
        const srcSetData = await srcset(item["img"], 700, 600, item["designerId"], "featured", "designers");
        return {
            id: item["id"],
            src: srcSetData.src,
            srcSet: srcSetData.srcSet,
            alt: item["title"],
            designerId: item["designerId"],
            title: item["title"],
            designer: item["designer"]
        } as FeaturedProject;
    }));
};

export const processInspirationItems = async (featuredInspirationItems: any[]): Promise<Inspiration[]> => {
    return await Promise.all(featuredInspirationItems.map(async (item) => {
        const pictures = await fetchInspirationPictures(item, item["item_image"]);

        return {
            inspirationId: item["id"],
            alt: item["item_name"],
            designerId: item["designerId"],
            itemName: item["item_name"],
            itemDescription: item["item_description"],
            itemUrl: item["item_url"],
            dateAdded: item["dateAdded"],
            pictures: pictures
        } as Inspiration;
    }));
};

