import React, {useContext, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import {DesignersContext} from '../../../context/DesignersContext';
import {DarkGray, Gold, OffWhite} from '../../../theme/Colors';
import {Box, Card, CardMedia, CircularProgress, Container, Grid, Skeleton, Typography} from '@mui/material';
import defaultProfilePic from '../../../imgs/LogoIcon.png';
import {renderSocialIcons} from './Socialcons';
import ProjectGallery from './ProjectGallery';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import VideoGallery from './VideoGallery';
import ContactDesignerDialog from './ContactDesignerDialog';

const DesignersDetail = () => {
    const {designerId} = useParams();
    const {designersWithImages} = useContext(DesignersContext);

    const designer = useMemo(() => designersWithImages.find(d => d.designerId === designerId), [designersWithImages, designerId]);
    const [imageLoaded, setImageLoaded] = useState(false);

    if (!designer) {
        return (
            <Container maxWidth="lg">
                <Grid container spacing={2} justifyContent="center" alignItems="center" style={{minHeight: '100vh'}}>
                    <Grid item>
                        <CircularProgress color="inherit"/>
                    </Grid>
                </Grid>
            </Container>
        );
    }

    return (
        <Grid item xs={12}>
            <Box display="flex" justifyContent="center" marginBottom="2rem">
                <Typography
                    variant="h4"
                    align="center"
                    sx={{
                        filter: designer.bundle === 'free' ? 'blur(8px)' : 'none',
                        pointerEvents: designer.bundle === 'free' ? 'none' : 'auto',
                        userSelect: designer.bundle === 'free' ? 'none' : 'auto',
                    }}
                >
                    {designer.businessName}
                </Typography>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={12} md={3}
                      sx={{
                          filter: designer.bundle === 'free' ? 'blur(4px)' : 'none',
                          pointerEvents: designer.bundle === 'free' ? 'none' : 'auto',
                          userSelect: designer.bundle === 'free' ? 'none' : 'auto',
                      }}
                >
                    <Card
                        variant="outlined"
                        sx={{
                            marginBottom: 2,
                            backgroundColor: OffWhite,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            padding: '2rem',
                            '&:hover': {
                                boxShadow: 'none',
                                textDecoration: 'none',
                                transform: 'none',
                            },
                        }}
                    >
                        {!imageLoaded && (
                            <Skeleton
                                variant="rectangular"
                                width="100%"
                                height={"20rem"}
                            />
                        )}
                        <CardMedia
                            component="img"
                            image={designer.profilePicture || defaultProfilePic}
                            alt="Designer profile"
                            sx={{
                                height: 'auto',
                                marginBottom: '2rem',
                                display: 'block',
                                visibility: imageLoaded ? 'visible' : 'hidden',
                                opacity: imageLoaded ? 1 : 0,
                                transition: 'opacity 0.5s ease',
                            }}
                            style={{
                                objectFit: 'contain',
                                width: '100%',
                                height: '100%',
                            }}
                            onLoad={() => setImageLoaded(true)}
                        />
                        <Typography variant="h5" color={Gold} sx={{textAlign: 'center'}}>
                            {designer.fullName}
                        </Typography>
                        <Typography variant="h6" color={DarkGray}>
                            {Array.isArray(designer.profession)
                                ? designer.profession.map((prof, index) => (
                                    <><span key={index}>{prof}</span><br/></>
                                ))
                                : designer.profession}
                        </Typography>
                        {renderSocialIcons(designer.socials, designer.fullName)}
                        <ContactDesignerDialog designerEmail={designer.email} designerName={designer.fullName}/>
                        <Typography variant="body1" sx={{mt: '1rem'}}>
                            {designer.designerDescription}
                        </Typography>
                    </Card>
                </Grid>
                <Grid item xs={12} md={9}>
                    {Object.entries(designer.projects)
                        .filter(([projectId, projectDetails]) =>
                            projectDetails.project_name.trim() !== "" || projectDetails.project_description.trim() !== ""
                        )
                        .sort(([aId], [bId]) => {
                            const aMatch = aId.match(/\d+$/);
                            const bMatch = bId.match(/\d+$/);

                            if (!aMatch || !bMatch) {
                                return 0;
                            }

                            const aNum = parseInt(aMatch[0], 10);
                            const bNum = parseInt(bMatch[0], 10);
                            return aNum - bNum;
                        })
                        .map(([projectId, projectDetails]) => (
                            <Box key={projectId} sx={{marginBottom: 4}}>
                                {designer.projectPictures && designer.projectPictures[projectId] && (
                                    <ProjectGallery pictures={designer.projectPictures[projectId]}
                                                    projectKey={projectId}/>
                                )}
                                <Typography variant="body1" sx={{marginBottom: 4, marginTop: 8}}>
                                    {projectDetails.project_name}
                                </Typography>
                                <Typography variant="body2" sx={{marginBottom: 16}}>
                                    {projectDetails.project_description}
                                </Typography>
                            </Box>
                        ))
                    }

                    {designer.bundle === 'premium+' && (
                        <VideoGallery designer={designer}/>
                    )}

                </Grid>
            </Grid>
        </Grid>
    );
}

export default DesignersDetail;
