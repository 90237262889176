import {useCallback, useEffect} from 'react';

const useScrollMemory = (path: string) => {
    const saveScrollPosition = useCallback(() => {
        sessionStorage.setItem(`scrollPosition-${path}`, window.scrollY.toString());
    }, [path]);

    useEffect(() => {
        const scrollPos = sessionStorage.getItem(`scrollPosition-${path}`);
        if (scrollPos) {
            setTimeout(() => {
                window.scrollTo({
                    top: parseInt(scrollPos),
                    behavior: 'smooth'
                });
            }, 100);
            sessionStorage.removeItem(`scrollPosition-${path}`);
        }

        window.addEventListener('beforeunload', saveScrollPosition);

        return () => {
            window.removeEventListener('beforeunload', saveScrollPosition);
            // Optionally clear on unmount if no longer needed
        };
    }, [saveScrollPosition, path]);

    return saveScrollPosition;
};

export default useScrollMemory;
