import React, {useState} from 'react';
import {Alert, Backdrop, Box, Button, CircularProgress, Grid, Snackbar, TextField, Typography} from '@mui/material';

const Contact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [description, setDescription] = useState('');
    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [severity, setSeverity] = useState<'success' | 'error'>('success');

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value);
    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value);
    const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => setPhone(event.target.value);
    const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => setDescription(event.target.value);
    const handleSnackbarClose = () => setSnackbarOpen(false);

    const sendEmailToContact = async () => {
        setLoading(true);
        const endpoint = 'https://europe-west1-design-connect.cloudfunctions.net/sendEmail';
        try {
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    to: "info@design-connect.com",
                    subject: 'Bedrijf wil contact opnemen',
                    text: `${name} heeft interesse om op ons platform te staan. Dit zijn zijn gegevens:\n\nEmail: ${email}\n\nTelefoon: ${phone}\n\nBeschrijving: ${description}`,
                }),
            });

            if (!response.ok) throw new Error('Network response was not ok');
            setSnackbarMessage('Email succesvol verzonden');
            setSeverity('success');
            setSnackbarOpen(true);
        } catch (error) {
            console.error('Error sending email:', error);
            setSnackbarMessage('Fout bij het verzenden van de email');
            setSeverity('error');
            setSnackbarOpen(true);
        }
        setLoading(false);
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="center" marginBottom="1rem">
                        <Typography variant="h4" align="center">Contact opnemen</Typography>
                    </Box>
                    <form onSubmit={async (event) => {
                        event.preventDefault();
                        await sendEmailToContact();
                    }}>
                        <Grid item xs={12} md={8} style={{margin: 'auto'}}>
                            <Typography variant="body1" align="center" marginBottom="1rem">
                                Ook hier op de website staan? Neem contact met ons op door onderstaand formulier in te
                                vullen!
                            </Typography>
                            <TextField autoFocus required margin="dense" label="Naam" fullWidth value={name}
                                       onChange={handleNameChange} sx={{marginBottom: 2}}/>
                            <TextField required margin="dense" label="Email" type="email" fullWidth value={email}
                                       onChange={handleEmailChange} sx={{marginBottom: 2}}/>
                            <TextField required margin="dense" label="Telefoon" fullWidth value={phone}
                                       onChange={handlePhoneChange} sx={{marginBottom: 2}}/>
                            <TextField required margin="dense" label="Beschrijf je bedrijf" fullWidth multiline rows={4}
                                       value={description}
                                       onChange={handleDescriptionChange} sx={{marginBottom: 2}}/>
                            <Box display="flex" justifyContent="center" marginBottom="1rem">
                                <Button type="submit" variant="outlined">Verstuur</Button>
                            </Box>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
            <Backdrop open={loading} sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={severity} variant="filled" sx={{width: '100%'}}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
}

export default Contact;
