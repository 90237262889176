import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import LanguageIcon from '@mui/icons-material/Language';
import PinterestIcon from '@mui/icons-material/Pinterest';
import {LinkedIn} from "@mui/icons-material";
import {Box, IconButton, Tooltip} from "@mui/material";
import React from "react";
import {logEvent} from "firebase/analytics";
import {analytics} from "../../../FirebaseConfig";

type SocialPlatform = 'Instagram' | 'Twitter' | 'Facebook' | 'Website' | 'Linkedin' | 'Pinterest'; // Extend as needed

const socialcons: Record<SocialPlatform, JSX.Element> = {
    Instagram: <InstagramIcon fontSize="inherit"/>,
    Twitter: <TwitterIcon fontSize="inherit"/>,
    Facebook: <FacebookIcon fontSize="inherit"/>,
    Website: <LanguageIcon fontSize="inherit"/>,
    Linkedin: <LinkedIn fontSize="inherit"/>,
    Pinterest: <PinterestIcon fontSize="inherit"/>,
    // Add other platforms as needed
};
const designerWebsiteClick = (designerName: string, designerWebsite: string) => {
    console.log(designerWebsite);
    logEvent(analytics, 'designer_website_click', {'designer_name': designerName})
    window.open(designerWebsite, '_blank', 'noopener,noreferrer');
}

export const renderSocialIcons = (socials: Record<string, string>, designerName: string) => (
    <Box sx={{display: 'flex', justifyContent: 'center', gap: '1rem', margin: '1rem 0'}}>
        {Object.entries(socials)
            .filter(([platform, link]) => link.trim() !== "")
            .map(([platform, link]) => {
                if (platform in socialcons) {
                    const Icon = socialcons[platform as SocialPlatform];
                    return (
                        <Tooltip key={platform} title={`Ga naar ${platform}`} arrow>
                            <IconButton // Using IconButton for clickable icons
                                onClick={() => designerWebsiteClick(designerName, link)}
                                aria-label={platform}
                                color="inherit"
                                size="large"
                            >
                                {Icon}
                            </IconButton>
                        </Tooltip>
                    );
                }
                return null;
            })
        }
    </Box>
);
