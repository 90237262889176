import React from 'react';
import {Tooltip, Typography, useMediaQuery} from "@mui/material";
import {theme} from "../../theme/Theme";
// Import images statically
import bannerImage1 from '../../imgs/Banner1.jpeg';
import bannerImage2 from '../../imgs/Banner2.jpeg';
import bannerImage3 from '../../imgs/Banner3.jpeg';
import bannerImage4 from '../../imgs/Banner4.jpeg';
import bannerImage5 from '../../imgs/Banner5.jpeg';

interface BannerProps {
    navigate: (path: string) => void;
}

const Banner = React.memo(({navigate}: BannerProps) => {
    const title = "Vind moeiteloos uw ideale ontwerp professional!";
    const handleClick = () => {
        navigate('/designers'); // Navigate to designers page
    };
    const isMedium = useMediaQuery(theme.breakpoints.up('md'));

    // Function to determine which banner to show based on the day of the month
    const getDailyBanner = () => {
        const dayOfMonth = new Date().getDate(); // Get the current day of the month
        // Decide which banners to use based on the screen size
        const banners = isMedium ? [bannerImage1, bannerImage2, bannerImage3] : [bannerImage4, bannerImage5];
        return banners[dayOfMonth % banners.length]; // Rotate through banners
    };

    const bannerImage = getDailyBanner(); // Use the function to get the daily banner

    return (
        <div onClick={handleClick} style={{cursor: 'pointer'}}> {/* Make the whole div clickable */}
            <>
                <img src={bannerImage} className="homeBanner" alt="banner" loading="lazy"/>
                <Typography
                    variant="h4"
                    sx={{padding: "4rem"}}
                >
                    <Tooltip title="Ga naar ontwerpers">
                        <span>{title.toUpperCase()}</span>
                    </Tooltip>
                </Typography>
            </>
        </div>
    );
});

export default Banner;
