import Grid from "@mui/material/Grid";
import {Typography} from "@mui/material";

const Faq = () => {
    return (
        <Grid item xs={12} md={8} style={{margin: 'auto'}} className="privacyContent">
            <Typography variant="h5">1. Hoe vind ik een interieurontwerper of architect op Design Connect?</Typography>
            <Typography variant="h6">Welke stappen moet ik volgen om een professional te vinden die bij mijn project
                past?</Typography>
            <Typography variant="body1">- Door middel van het filtersysteem bij de ontwerperspagina, kunt u gemakkelijk
                de juiste professional
                vinden die aan uw wensen voldoet.</Typography>

            <Typography variant="h5">2. Is het mogelijk om op Design Connect te zoeken zonder een account aan te
                maken?</Typography>
            <Typography variant="h6">Heb ik een account nodig om door de profielen en portfolio's van ontwerpers te
                bladeren?</Typography>
            <Typography variant="body1">- Nee, u kunt zonder aan te melden volledig gebruik maken van het platform.
                Echter, als u zich aanmeldt kunt
                u uw favoriete ontwerpers en projecten opslaan en deze dus gemakkelijk terug vinden.</Typography>

            <Typography variant="h5">3. Kan ik direct contact opnemen met professional via Design Connect?</Typography>
            <Typography variant="h6">Hoe kan ik communiceren met ontwerpers als ik geïnteresseerd ben in hun
                werk?</Typography>
            <Typography variant="body1">- Wanneer u een professional heeft gevonden die bij u past, kunt u via het
                profiel van de professional
                contact opnemen.</Typography>

            <Typography variant="h5">4. Zijn er kosten verbonden aan het gebruik van Design Connect voor mijn
                project?</Typography>
            <Typography variant="h6">Moet ik betalen om professionals te vinden op Design Connect?</Typography>
            <Typography variant="body1">- Nee, het gebruik maken van het platform en contact opnemen met ontwerpers is
                kosteloos.</Typography>

            <Typography variant="h5">5. Hoe weet ik dat de ontwerpers op Design Connect betrouwbaar zijn?</Typography>
            <Typography variant="h6">Wat voor verificatieproces doorlopen de professionals voordat ze op het platform
                verschijnen?
            </Typography>
            <Typography variant="body1">- Alle professionals die aangemeld zijn bij Design Connect, zijn door een
                zorgvuldige screening gegaan,
                begeleid door onze werknemers.</Typography>

            <Typography variant="h5">6. Kan ik beoordelingen en reviews van ontwerpers zien op Design
                Connect?</Typography>
            <Typography variant="h6">Waar vind ik feedback van andere klanten over een professional?</Typography>
            <Typography variant="body1">- Als een professional beoordeling en/of reviews heeft, zijn deze onderaan het
                profiel van de professional
                te vinden.</Typography>

            <Typography variant="h5">7. Wat gebeurt er nadat ik een projectaanvraag heb verzonden?</Typography>
            <Typography variant="h6">Hoelang duurt het voordat ik een reactie kan verwachten van een
                professional?</Typography>
            <Typography variant="body1">- Nadat u contact heeft opgezocht met uw uitgekozen professional zal hij of zij
                zo spoedig mogelijk contact
                met u opnemen. Elke professional heeft een andere hoeveelheid tijd nodig om te reageren, hier valt dus
                geen
                maat aan te hangen.</Typography>
        </Grid>
    )
}
export default Faq;
