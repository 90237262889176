import {Grid, Link, Typography} from "@mui/material";
import "./Privacy.css";

const Privacy = () => {
    return (
        <Grid item xs={12} md={8} style={{margin: 'auto'}} className="privacyContent">
            <Typography variant="h6">Inleiding</Typography>
            <Typography variant="body1">1.1. Dit Privacy- en Cookiebeleid beschrijft hoe Design Connect ("wij",
                "ons" of "ons platform") informatie verzamelt, gebruikt en openbaar maakt wanneer gebruikers onze
                website bezoeken of gebruikmaken van onze diensten.
                <br/>1.2. Door gebruik te maken van ons platform stemt u in met de verzameling en het gebruik van
                informatie in overeenstemming met dit beleid.
            </Typography>
            <Typography variant="h6">Informatie die we verzamelen</Typography>
            <Typography variant="body1">2.1. Persoonlijk identificeerbare informatie: We kunnen persoonlijk
                identificeerbare informatie verzamelen, zoals naam, e-mailadres, wanneer gebruikers zich registreren
                voor de nieuwsbrief of contact met ons opnemen.
                <br/>2.2. Niet-persoonlijk identificeerbare informatie: We kunnen ook niet-persoonlijk
                identificeerbare informatie verzamelen, zoals IP-adressen, browsertype, taalvoorkeuren, verwijzende
                pagina's en tijdstippen van toegang, om de werking van ons platform te verbeteren en
                gepersonaliseerde gebruikerservaringen te bieden.
            </Typography>
            <Typography variant="h6">Hoe we informatie gebruiken</Typography>
            <Typography variant="body1">3.1. We gebruiken de verzamelde informatie om onze diensten te leveren,
                gebruikersaccounts te beheren, transacties te verwerken, klantenondersteuning te bieden en ons
                platform te verbeteren.
                <br/>3.2. We kunnen persoonlijke informatie ook gebruiken om gebruikers op de hoogte te houden van
                updates, aanbiedingen en promoties, mits ze daarvoor toestemming hebben gegeven.
            </Typography>
            <Typography variant="h6">Openbaarmaking van informatie</Typography>
            <Typography variant="body1">4.1. We delen persoonlijke informatie alleen met derden zoals
                betalingsverwerkers en serviceproviders om onze diensten te leveren en te verbeteren, tenzij anders
                vereist door de wet of zoals beschreven in dit beleid.
                <br/>4.2. We verkopen of verhuren uw persoonlijke informatie niet aan derden voor
                marketingdoeleinden.
            </Typography>
            <Typography variant="h6">Cookies en soortgelijke technologieën</Typography>
            <Typography variant="body1">5.1. We gebruiken cookies, web beacons en andere soortgelijke technologieën
                om informatie te verzamelen en op te slaan wanneer gebruikers onze website bezoeken.
                <br/>5.2. Cookies stellen ons in staat om gebruikers te herkennen, hun voorkeuren te onthouden en
                gebruikerservaringen te personaliseren.
                <br/>5.3. Gebruikers kunnen cookies beheren of verwijderen via de instellingen van hun browser. Het
                uitschakelen van cookies kan van invloed zijn op de functionaliteit van ons platform.
            </Typography>
            <Typography variant="h6">Gegevensbewaring</Typography>
            <Typography variant="body1">6.1. We bewaren persoonlijke informatie alleen zo lang als nodig is voor de
                doeleinden waarvoor deze is verzameld, tenzij anders vereist door de wet.
                <br/>6.2. Geanonimiseerde of geaggregeerde informatie kan worden bewaard voor statistische analyse
                en rapportagedoeleinden.
            </Typography>
            <Typography variant="h6">Beveiliging</Typography>
            <Typography variant="body1">7.1. We nemen redelijke maatregelen om persoonlijke informatie te beschermen
                tegen verlies, misbruik en ongeoorloofde toegang.
                <br/>7.2. Ondanks onze inspanningen kunnen we echter geen absolute garantie bieden voor de
                veiligheid van informatie tijdens verzending via het internet of opslag op onze systemen.
            </Typography>
            <Typography variant="h6">Wijzigingen in dit beleid</Typography>
            <Typography variant="body1">8.1. We kunnen dit Privacy- en Cookiebeleid van tijd tot tijd wijzigen om
                wijzigingen in onze bedrijfsvoering of wetgeving weer te geven.
                <br/>8.2. Wijzigingen in dit beleid worden van kracht zodra ze op onze website zijn gepubliceerd.
            </Typography>
            <Typography variant="h6">Contact met ons opnemen</Typography>
            <Typography variant="body1">9.1. Als u vragen heeft over dit Privacy- en Cookiebeleid of over de
                praktijken van ons platform met betrekking tot privacy, neem dan contact met ons op via de
                contactgegevens die hieronder worden vermeld.

                <br/>Voor verdere vragen of opmerkingen over ons privacy- en cookiebeleid kunt u contact met ons
                opnemen via <Link href="mailto:info@design-connect.com">info@design-connect.com</Link></Typography>
        </Grid>
    )
}
export default Privacy;
