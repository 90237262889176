import {createTheme, outlinedInputClasses} from "@mui/material";
import {DarkGray, Gold, OffWhite} from "./Colors";

export const theme = createTheme({
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontFamily: '"Montserrat", sans-serif',
                    fontStyle: "normal",
                    fontWeightRegular: 400,
                }
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: DarkGray, textDecorationColor: DarkGray
                }
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    color: DarkGray,
                    borderColor: DarkGray,
                    fontFamily: "Montserrat",

                    '&:hover': {
                        backgroundColor: OffWhite,
                        borderColor: Gold,
                    }
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.1)', // Add background color on hover
                    },
                }
            }
        },
        MuiFab: {
            styleOverrides: {
                primary: {
                    backgroundColor: Gold,
                    '&:hover': {
                        backgroundColor: OffWhite,
                        color: Gold
                    },
                },
                secondary: {
                    color: DarkGray,
                    backgroundColor: OffWhite,
                    '&:hover': {
                        backgroundColor: OffWhite,
                        color: Gold
                    },
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    '&.Mui-checked': {
                        color: Gold, // Change the color of the checkbox when checked
                    },
                    '&.Mui-disabled': {
                        color: "#00000042",
                    }
                },
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    color: DarkGray,
                    '&.Mui-disabled': {
                        color: DarkGray,
                    }
                },
            }
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    backgroundColor: OffWhite
                }
            }
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    justifyContent: "center",
                }
            }
        },
        MuiDialogContentText: {
            styleOverrides: {
                root: {
                    marginBottom: "1rem"
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '--TextField-brandBorderColor': DarkGray,
                    '--TextField-brandBorderHoverColor': Gold,
                    '--TextField-brandBorderFocusedColor': Gold,
                    '& label.Mui-focused': {
                        color: 'var(--TextField-brandBorderFocusedColor)',
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    borderColor: 'var(--TextField-brandBorderColor)',
                },
                root: {
                    [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                        borderColor: 'var(--TextField-brandBorderHoverColor)',
                    },
                    [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                        borderColor: 'var(--TextField-brandBorderFocusedColor)',
                    },
                },
            },
        },
        MuiImageList: {
            styleOverrides: {
                root: {
                    cursor: "pointer"
                }
            }
        },
        MuiImageListItemBar: {
            styleOverrides: {
                root: {
                    fontFamily: "Montserrat",
                },
                titleWrap: {
                    padding: "1rem",
                },
                title: {}
            }
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: OffWhite,
                }
            }
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    backgroundColor: OffWhite,
                }
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    backgroundColor: OffWhite,
                    borderRadius: 0,
                    marginBottom: "2rem",
                    transition: "0.4s", // Smooth transition for the shadow
                    '&:hover': {
                        transform: "translateY(-4px)",
                        boxShadow: "0 4px 20px 0 rgba(0,0,0,0.12)",
                        backgroundColor: OffWhite, // Lighten the background on hover
                    }
                }
            }
        }
    },
});
