import {Grid, Typography} from "@mui/material";

const TermsAndConditions = () => {
    return (
        <Grid item xs={12} md={8} style={{margin: 'auto'}} className="privacyContent">
            <Typography variant="h6">Definities</Typography>
            <Typography variant="body1">
                1.1. "Platform" verwijst naar Design connect, een online platform waar ontwerpers hun creatieve
                werken kunnen presenteren, verkopen en kopen.
                <br/>1.2. "Gebruiker" verwijst naar een persoon die het platform gebruikt, inclusief maar niet
                beperkt tot ontwerpers en andere bezoekers van de website.
                <br/>1.3. "Content" verwijst naar alle informatie, tekst, afbeeldingen, video's, ontwerpen en andere
                materialen die beschikbaar zijn op het platform.
            </Typography>
            <Typography variant="h6">Acceptatie van de Algemene Voorwaarden</Typography>
            <Typography variant="body1">
                2.1. Door gebruik te maken van het platform stemt de gebruiker ermee in gebonden te zijn aan deze
                Algemene Voorwaarden.
                <br/>2.2. Het gebruik van het platform is voorbehouden aan personen die wettelijk bevoegd zijn om
                contractuele verplichtingen aan te gaan.
            </Typography>
            <Typography variant="h6">Gebruik van het Platform</Typography>
            <Typography variant="body1">
                3.1. Gebruikers mogen het platform alleen gebruiken voor wettige doeleinden en op een manier die
                geen inbreuk maakt op de rechten van anderen.
                <br/>3.2. Gebruikers mogen geen ongeoorloofde toegang proberen te verkrijgen tot delen van het
                platform of tot systemen of netwerken die verbonden zijn met het platform.
                <br/>3.3. Het platform behoudt zich het recht voor om de toegang van gebruikers tot het platform op
                elk moment en om welke reden dan ook te beperken of te beëindigen.
            </Typography>
            <Typography variant="h6">Intellectuele Eigendom</Typography>
            <Typography variant="body1">
                4.1. Gebruikers behouden alle intellectuele eigendomsrechten op de content die zij op het platform
                plaatsen.
                <br/>4.2. Door content op het platform te plaatsen, verlenen gebruikers het platform een
                wereldwijde, niet-exclusieve, royaltyvrije licentie om de content te gebruiken, reproduceren,
                aanpassen, publiceren en verspreiden voor het beheer en de promotie van het platform.
            </Typography>
            <Typography variant="h6">Beperking van Aansprakelijkheid</Typography>
            <Typography variant="body1">
                5.1. Het platform is niet aansprakelijk voor directe, indirecte, incidentele, speciale of
                gevolgschade die voortvloeit uit het gebruik van het platform.
                <br/>5.2. Het platform biedt geen garanties met betrekking tot de nauwkeurigheid, betrouwbaarheid of
                volledigheid van de content op het platform.
            </Typography>
            <Typography variant="h6">Wijzigingen in de Algemene Voorwaarden</Typography>
            <Typography variant="body1">
                6.1. Het platform behoudt zich het recht voor om deze Algemene Voorwaarden op elk moment te
                wijzigen.
                <br/>6.2. Wijzigingen in de Algemene Voorwaarden worden van kracht zodra ze op het platform worden
                gepubliceerd.
                <br/>6.3. Gebruikers worden aangemoedigd om de Algemene Voorwaarden regelmatig te controleren op
                updates.
            </Typography>
            <Typography variant="h6">Toepasselijk Recht</Typography>
            <Typography variant="body1">
                7.1. In het geval van een geschil tussen gebruikers en het platform, zullen partijen trachten
                het geschil op een minnelijke manier op te lossen voordat zij juridische stappen ondernemen.
                <br/><br/>
                Door gebruik te maken van het platform stemt de gebruiker ermee in gebonden te zijn aan deze
                Algemene Voorwaarden. Het niet naleven van deze voorwaarden kan leiden tot beperkingen op het
                gebruik van het platform.
            </Typography>
        </Grid>
    )
}
export default TermsAndConditions;
