import React, {useCallback, useEffect, useRef, useState} from "react";
import {
    Box,
    Card,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    Skeleton,
    Typography,
    useMediaQuery
} from "@mui/material";
import {theme} from "../../theme/Theme";
import {fetchInspirationsQuery} from "../../repositories/InspirationRepository";
import {useNavigate} from "react-router-dom";
import {logEvent} from "firebase/analytics";
import {analytics} from "../../FirebaseConfig";
import {processInspirationItems} from "../../utils/ImageListHelper";
import {Inspiration} from "../../interfaces/Inspiration";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import {DarkGray} from "../../theme/Colors";
import {CustomArrowLeft, CustomArrowRight, StyledCarousel} from "../../theme/Carousel";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import defaultProfilePic from '../../imgs/LogoIcon.png';

interface DialogContentState {
    images: string[];
    title: string;
    description: string;
    designerId?: string;
    designerUrl?: string;
}

type ObjectFit = 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';

const InspirationPage = ({}) => {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [widthCol, setWidthCol] = useState(2);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState<DialogContentState>({images: [], title: '',description: '', designerId: undefined, designerUrl: undefined});
    const [objectFitStyle, setObjectFitStyle] = useState<ObjectFit>('contain');
    const imageRef = useRef<HTMLImageElement>(null);

    const updateObjectFitStyle = (img: HTMLImageElement) => {
        if (img.naturalWidth > img.naturalHeight) {
            setObjectFitStyle('cover');
        } else {
            setObjectFitStyle('contain');
        }
    };

    useEffect(() => {
        if (imageRef.current) {
            updateObjectFitStyle(imageRef.current);
        }
    }, [dialogContent.images]);

    useEffect(() => {
        if (isMobile) {
            setWidthCol(1);
        } else {
            setWidthCol(2);
        }
    }, [isMobile]);

    const [fetchedInspirationItems, fetchedInspirationLoading] = fetchInspirationsQuery();
    const [inspirations, setInspirations] = useState<Inspiration[]>([]);

    useEffect(() => {
        const fetchFeaturedItems = async () => {
            if (!fetchedInspirationLoading) {
                if (fetchedInspirationItems) {
                    const processedInspirationItems = await processInspirationItems(fetchedInspirationItems);
                    setInspirations(processedInspirationItems);
                }
                setLoading(false);
            }
        };

        fetchFeaturedItems();
    }, [fetchedInspirationItems, fetchedInspirationLoading]);

    const handleOpenDialog = (pictures: string[], title: string, description: string, designerId?: string, designerUrl?: string) => {
        setDialogContent({ images: pictures, title, description, designerId, designerUrl });
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const inspirationClick = useCallback((designerName: string, designerId?: string, url?: string) => {
        logEvent(analytics, 'inspiration_click', { 'item_name': designerName });
        if (url) {
            window.location.href = url;
        } else if (designerId) {
            navigate(`/designers/${designerId}`);
        }
    }, [navigate]);

    return (
        <>
            <Grid item xs={12} sx={{ textAlign: "center", justifyContent: "center" }}>
                <Box display="flex" justifyContent="center" marginBottom="2rem">
                    <Typography variant="h4">Inspiratie</Typography>
                </Box>
                <ImageList sx={{ width: "100%", height: "auto", margin: "auto", paddingBottom: "2rem" }}
                           gap={12} cols={widthCol}>
                    {loading ? (
                        <Skeleton variant="rectangular" width={"80%"} height={"auto"}>
                            <ImageListItem />
                        </Skeleton>
                    ) : (
                        inspirations.map((item) => (
                            <Card key={item.inspirationId} variant="outlined">
                                <ImageListItem key={item.inspirationId}>
                                    <img
                                        src={item.pictures.length > 0 ? item.pictures[0] : defaultProfilePic}
                                        alt={item.alt}
                                        loading="lazy"
                                        ref={imageRef}
                                        style={{
                                            width: '100%',
                                            height: 'auto',
                                            maxHeight: '30rem',
                                            objectFit: objectFitStyle,
                                        }}
                                        onLoad={(e) => updateObjectFitStyle(e.currentTarget)}
                                        onClick={() => handleOpenDialog(item.pictures, item.itemName, item.itemDescription, item.designerId, item.itemUrl)} />
                                    <ImageListItemBar title={item.itemName} position="below" />
                                </ImageListItem>
                            </Card>
                        ))
                    )}
                </ImageList>
            </Grid>

            <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="md" fullWidth
                    sx={{
                        '& .MuiDialog-paper': {
                            m: 0,
                            maxWidth: '100%',
                            maxHeight: '100%',
                        }
                    }}>
                <IconButton onClick={handleCloseDialog}
                            sx={{ position: 'absolute', right: 8, top: 8, color: DarkGray, zIndex: 1 }}>
                    <CloseIcon />
                </IconButton>
                <DialogTitle id="dialog-title">{dialogContent.title}</DialogTitle>
                <DialogContent>
                    <StyledCarousel
                        showArrows={true}
                        swipeable={true}
                        useKeyboardArrows={true}
                        showThumbs={false}
                        showStatus={false}
                        showIndicators={false}
                        dynamicHeight={true}
                        emulateTouch={true}
                        infiniteLoop={true}
                        renderArrowPrev={(onClickHandler, hasPrev, label) =>
                            hasPrev && (
                                <CustomArrowLeft onClick={onClickHandler} className="custom-arrow custom-arrow-left"
                                                 aria-label="Previous" />
                            )
                        }
                        renderArrowNext={(onClickHandler, hasNext, label) =>
                            hasNext && (
                                <CustomArrowRight onClick={onClickHandler} className="custom-arrow custom-arrow-right"
                                                  aria-label="Next" />
                            )
                        }
                    >
                        {dialogContent.images.map((src, index) => (
                            <div key={index} style={{}}>
                                <img
                                    ref={imageRef}
                                    src={src}
                                    alt={`${dialogContent.title} Picture ${index + 1}`}
                                    onLoad={() => {
                                        if (imageRef.current) {
                                            updateObjectFitStyle(imageRef.current);
                                        }
                                    }}
                                    style={{
                                        maxHeight: '90vh',
                                        width: '100%',
                                        objectFit: objectFitStyle
                                    }}
                                />
                            </div>
                        ))}
                    </StyledCarousel>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="body1">{dialogContent.description}</Typography>
                        <IconButton onClick={() => inspirationClick(dialogContent.title, dialogContent.designerId, dialogContent.designerUrl)}
                                    sx={{ color: DarkGray, zIndex: 1 }}>
                            <OpenInNewIcon />
                        </IconButton>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default InspirationPage;
