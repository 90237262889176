import defaultProfilePic from '../imgs/LogoIcon.png';
import {Inspiration} from "../interfaces/Inspiration";
import {DownloadUrl} from "../utils/DownloadUrl";

export async function fetchInspirationPictures(inspiration: Inspiration, folderName: string) {
    if (!folderName) {
        return []; // Return empty array if no folder is selected
    }

    const encodedInspirationId = encodeURIComponent(inspiration.inspirationId);
    const encodedFolderName = encodeURIComponent(folderName);

    try {
        const url = `https://europe-west1-design-connect.cloudfunctions.net/listInspirationPictures?inspirationId=${encodedInspirationId}&folderName=${encodedFolderName}`;
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('Failed to fetch inspiration pictures');
        }
        const fileNamesResponse = await response.json();
        const fileNames: string[] = fileNamesResponse.data || [];

        const processedInspirationPictures: string[] = await Promise.all(fileNames.map(async (fileName: string) => {
            const filePath = `inspiration/${inspiration.inspirationId}/${folderName}/${fileName}`;
            return DownloadUrl('inspiration', inspiration.inspirationId, '', fileName, folderName).catch(error => {
                return defaultProfilePic; // Default picture in case of error
            });
        }));

        return processedInspirationPictures.filter(pic => pic !== defaultProfilePic);
    } catch (error) {
        return []; // Return empty array in case of error
    }
}
