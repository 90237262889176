import Grid from "@mui/material/Grid";
import "./Footer.css";
import {Container, Link, Typography} from "@mui/material";
import {NavLink} from "react-router-dom";
import React from "react";
import {Instagram, Pinterest} from "@mui/icons-material";
import {logEvent} from "firebase/analytics";
import {analytics} from "../../FirebaseConfig";

const Footer = () => {
    const openInNewTab = (url: string) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    const handleOnClick = (social: string) => {
        logEvent(analytics, 'social_click', {'social_name': social});
    };


    return (
        <>
            <div className="footerGrid">
                <Container maxWidth={"lg"}>
                    <Grid container rowSpacing={2} className="footerContent">
                        <Grid item sm={3} xs={12}>
                            <Link component="button"
                                  onClick={() => {
                                      handleOnClick("Instagram");
                                      openInNewTab("https://www.instagram.com/designconnect_nl");
                                  }}
                            >
                                <Instagram fontSize="large"/>
                            </Link>
                            <Link component="button"
                                  sx={{marginLeft: "2rem"}}
                                  onClick={() => {
                                      handleOnClick("Pinterest");
                                      openInNewTab("https://www.pinterest.com/DesignConnectNL");
                                  }}
                            >
                                <Pinterest fontSize="large"/>
                            </Link>
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <NavLink to="/contact"><Typography sx={{fontSize: "0.875rem"}}>MELD JE AAN ALS
                                PROFESSIONAL</Typography></NavLink>
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <NavLink to="/faq"><Typography sx={{fontSize: "0.875rem"}}>FAQ</Typography></NavLink>
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <NavLink to="/conditions"><Typography sx={{fontSize: "0.875rem"}}>ALGEMENE
                                VOORWAARDEN</Typography></NavLink>
                            <NavLink to="/privacy"><Typography
                                sx={{fontSize: "0.875rem", marginTop: '0.75rem'}}>PRIVACY</Typography></NavLink>
                        </Grid>
                        <Grid item sm={12} xs={12} className="copyright">
                            <Typography variant="body2">© 2024 DESIGN CONNECT</Typography><br/>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </>
    )
};

export default Footer;
