import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Container, Grid, useMediaQuery} from "@mui/material";
import "./Home.css";
import {FeaturedProject} from "../../interfaces/FeaturedProject";
import {processFeaturedProjectItems} from "../../utils/ImageListHelper";
import {analytics} from "../../FirebaseConfig";
import {logEvent} from "firebase/analytics";
import {useNavigate} from "react-router-dom";
import Divider from '@mui/material/Divider';
import {theme} from "../../theme/Theme";
import {FeaturedProjectQuery} from "../../repositories/HomeRepository";
import {getRandomImageUrl} from "../../utils/GetRandomImage";
import Banner from "./Banner";
import FeaturedProjects from "./FeaturedProjects";
import FeaturedBlogs from "./FeaturedBlogs";
import HomeInspiration from "./HomeInspiration";
import {BlogContext} from "../../context/BlogContext";


const Home = () => {
    const [loading, setLoading] = useState(true);
    const [featuredProjects, setFeaturedProjects] = useState<FeaturedProject[]>([]);
    const [randomImageUrl, setRandomImageUrl] = useState('');
    const [projectWidthCol, setProjectWidthCol] = useState(2);
    const [blogWidthCol, setBlogWidthcol] = useState(4);
    const [mobileMargin, setMobileMargin] = useState("");
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();
    const {blogs: featuredBlogs, loading: blogsLoading} = useContext(BlogContext);

    const featuredProjectClick = useCallback((designerId: string, designerName: string) => {
        logEvent(analytics, 'homepage_featured_project_click', {'homepage_designer_name': designerName});
        navigate(`/designers/${designerId}`);
    }, [navigate]);

    const featuredBlogClick = useCallback((blogId: string, blogTitle: string) => {
        logEvent(analytics, 'homepage_featured_blog_click', {'homepage_blog_name': blogTitle});
        navigate(`/blogs/${blogId}`);
    }, [navigate]);

    const [fetchedFeaturedProjectItems, fetchedProjectLoading] = FeaturedProjectQuery();

    useEffect(() => {
        const fetchFeaturedItems = async () => {
            if (!fetchedProjectLoading && !blogsLoading) {
                if (fetchedFeaturedProjectItems) {
                    const processedFeaturedProjectItems = await processFeaturedProjectItems(fetchedFeaturedProjectItems);
                    setFeaturedProjects(processedFeaturedProjectItems);
                }
                setLoading(false);
            }
        };

        fetchFeaturedItems();
    }, [fetchedFeaturedProjectItems, fetchedProjectLoading, blogsLoading]);

    useEffect(() => {
        const fetchRandomImage = async () => {
            const imageUrl = await getRandomImageUrl();
            setRandomImageUrl(imageUrl);
        };

        fetchRandomImage();
    }, []);

    useEffect(() => {
        if (isMobile) {
            setProjectWidthCol(1);
            setBlogWidthcol(1);
            setMobileMargin("2rem");
        } else {
            setProjectWidthCol(2);
            setBlogWidthcol(2);
            setMobileMargin("20rem");
        }
    }, [isMobile]);


    return (
        <Grid item xs={12} className="homeContent">
            <Banner navigate={navigate}/>
            <Divider/>
            <Container maxWidth="xl">
                <FeaturedProjects loading={loading} featured={featuredProjects} handleOnClick={featuredProjectClick}
                                  widthCol={projectWidthCol}/>
                <HomeInspiration randomImageUrl={randomImageUrl} mobileMargin={mobileMargin} navigate={navigate}/>
                <FeaturedBlogs loading={loading} widthCol={blogWidthCol} blogClick={featuredBlogClick}
                               navigate={navigate}
                               blogs={featuredBlogs}/>
            </Container>
        </Grid>
    );
}

export default Home;
