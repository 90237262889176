import {useEffect, useState} from "react";
import {collection, onSnapshot, orderBy, query} from "firebase/firestore";
import {db} from "../FirebaseConfig";
import {Blog} from '../interfaces/Blog'; // Updated Blog interface

export function fetchBlogsQuery(): [Blog[], boolean] {
    const [blogItems, setBlogItems] = useState<Blog[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = onSnapshot(
            query(collection(db, 'blogs'), orderBy('dateAdded', 'desc')),
            (snapshot) => {
                const fetchedBlogItems: Blog[] = [];
                snapshot.forEach((doc) => {
                    const data = doc.data();
                    // Ensure data is transformed to match the Blog interface
                    fetchedBlogItems.push({
                        blogId: doc.id,                    // Assuming Firestore document ID maps to blogId
                        blogTitle: data.blogTitle || "",  // Default to empty string if data.blogTitle is undefined
                        content: data.content || "",      // Default to empty string if data.content is undefined
                        dateAdded: data.dateAdded, // Convert Firestore Timestamp to Date object
                        thumbnailImg: data.thumbnailImg || "" // Default to empty string if data.thumbnailImg is undefined
                    });
                });
                setBlogItems(fetchedBlogItems);
                setLoading(false);
            }
        );

        return () => unsubscribe();
    }, []);

    return [blogItems, loading];
}
