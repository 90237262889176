import {useParams} from "react-router-dom";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {BlogContext} from "../../../context/BlogContext";
import Grid from "@mui/material/Grid";
import {Typography} from "@mui/material";
import defaultProfilePic from "../../../imgs/LogoIcon.png";
import {DownloadUrl} from "../../../utils/DownloadUrl";

const BlogsDetail = () => {
    const {blogId} = useParams();
    const {blogs} = useContext(BlogContext);
    const [thumbnailUrl, setThumbnailUrl] = useState("");

    const blog = useMemo(() => blogs.find(d => d.blogId === blogId), [blogs, blogId]);
    useEffect(() => {
        if (blog) {
            // Fetch the thumbnail URL using the DownloadUrl function
            DownloadUrl("blogs", blog.blogId, '', blog.thumbnailImg).then(url => {
                setThumbnailUrl(url);
            }).catch(error => {
                console.error("Error fetching thumbnail URL:", error);
                // Set default profile pic if fetching thumbnail fails
                setThumbnailUrl(defaultProfilePic);
            });
        }
    }, [blog]);

    if (!blog) {
        return (
            <Grid item xs={12}>
                <Typography variant="h4" align="center" sx={{mb: 4}}>Geen blog gevonden</Typography>
            </Grid>
        );
    }
    return (
        <Grid item xs={12}>
            <Typography variant="h4" align="center" sx={{mb: 4}}>
                {blog.blogTitle}
            </Typography>
            <img
                src={thumbnailUrl || defaultProfilePic}
                alt={blog.blogTitle || 'No title available'}
                loading="lazy"
                style={{
                    width: '100%',
                    height: 'auto',
                    maxHeight: '40rem',
                    display: 'block',
                    margin: 'auto',
                    objectFit: 'contain'
                }}
            />
            <Grid item xs={12} md={8} style={{margin: 'auto'}}>
                <Typography variant="body1" sx={{mb: 4}} dangerouslySetInnerHTML={{__html: blog.content}}/>
            </Grid>
        </Grid>

    );
}

export default BlogsDetail;
