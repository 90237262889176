import React, {useEffect, useState} from 'react';
import {Card, ImageList, ImageListItem, ImageListItemBar, Link, Skeleton, Typography} from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import {BlogsProps} from "../../interfaces/BlogsProps";
import defaultProfilePic from '../../imgs/LogoIcon.png';
import {DownloadUrl} from '../../utils/DownloadUrl'; // Import the DownloadUrl function

const FeaturedBlogs = ({loading, blogs: initialBlogs, blogClick, widthCol, navigate}: BlogsProps) => {
    const [blogs, setBlogs] = useState(initialBlogs); // State to store blogs with updated thumbnail URLs

    useEffect(() => {
        const fetchThumbnails = async () => {
            try {
                // Fetch and update thumbnail URLs for each blog
                const updatedBlogs = await Promise.all(initialBlogs.map(async (blog) => {
                    try {
                        const thumbnailUrl = await DownloadUrl("blogs", blog.blogId, "", blog.thumbnailImg);
                        return {...blog, thumbnailImg: thumbnailUrl}; // Update the thumbnailImg property
                    } catch (error) {
                        console.error("Error fetching thumbnail URL:", error);
                        // If fetching thumbnail fails, return the original blog
                        return blog;
                    }
                }));

                // Update blogs state with the fetched data
                setBlogs(updatedBlogs);
            } catch (error) {
                console.error("Error fetching thumbnails:", error);
            }
        };

        fetchThumbnails();
    }, [initialBlogs]);

    return (
        <>
            <Typography sx={{paddingTop: "4rem", paddingBottom: "4rem"}} variant="h4">BLOGS</Typography>
            <ImageList sx={{width: "100%", height: "auto", margin: "auto", paddingBottom: "2rem"}} gap={12}
                       cols={widthCol}>
                {loading ? (
                    Array.from(new Array(4)).map((_, index) => (
                        <Skeleton key={index} variant="rectangular" width={210} height={118}/>
                    ))
                ) : (
                    blogs.slice(0, 4).map((item) => (
                        <Card key={item.blogId} variant="outlined">
                            <ImageListItem key={item.blogId}>
                                <img
                                    src={item.thumbnailImg || defaultProfilePic} // Default placeholder if src is missing
                                    alt={item.blogTitle || 'Geen titel beschikbaar'} // Default text if alt is missing
                                    loading="lazy"
                                    onClick={() => blogClick(item.blogId, item.blogTitle)}
                                    style={{
                                        width: '100%',
                                        height: 'auto',
                                        maxHeight: '25rem',
                                        display: 'block',
                                        margin: 'auto'
                                    }}
                                />
                                <ImageListItemBar title={item.blogTitle} position="below" sx={{
                                    textOverflow: 'clip',
                                    whiteSpace: 'normal',
                                    overflow: 'visible',
                                    height: 'auto'
                                }}/>
                            </ImageListItem>
                        </Card>
                    ))
                )}
            </ImageList>
            <Link
                component="button"
                underline="hover"
                sx={{
                    marginBottom: "2rem",
                    fontSize: "1.5rem",
                    textDecoration: "none"
                }}
                onClick={() => {
                    navigate('/blogs');
                }}
            >
                Meer blogs kijken?<br/>
                <EastIcon fontSize="large"/>
            </Link>
        </>
    );
}

export default FeaturedBlogs;
