import {useEffect, useState} from 'react';
import {collection, limit, onSnapshot, orderBy, query, Unsubscribe} from 'firebase/firestore';
import {db} from '../FirebaseConfig';
import {FeaturedProject} from '../interfaces/FeaturedProject';

export function FeaturedProjectQuery(): [FeaturedProject[], boolean] {
    const [featuredProjectItems, setFeaturedProjectItems] = useState<FeaturedProject[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe: Unsubscribe = onSnapshot(
            query(collection(db, 'homepage'), limit(6), orderBy('dateAdded', 'desc')),
            (snapshot) => {
                const fetchedFeaturedItems: FeaturedProject[] = [];
                snapshot.forEach((doc) => {
                    const data = doc.data();
                    fetchedFeaturedItems.push({
                        id: doc.id,
                        ...data,
                    } as FeaturedProject);
                });
                setFeaturedProjectItems(fetchedFeaturedItems);
                setLoading(false);
            }
        );

        return () => unsubscribe();
    }, []);

    return [featuredProjectItems, loading];
}

