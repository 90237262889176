import React, {useState} from 'react';
import {
    Alert,
    Backdrop,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Snackbar,
    TextField
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface ContactDesignerDialogProps {
    designerEmail: string;
    designerName: string;
}

const ContactDesignerDialog = ({designerEmail, designerName}: ContactDesignerDialogProps) => {
    const [open, setOpen] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [question, setQuestion] = useState('');
    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [severity, setSeverity] = useState<'success' | 'error'>('success');

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setLoading(false);
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value);
    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value);
    const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => setPhone(event.target.value);
    const handleQuestionChange = (event: React.ChangeEvent<HTMLInputElement>) => setQuestion(event.target.value);
    const handleSnackbarClose = () => setSnackbarOpen(false);

    const sendEmailToDesigner = async () => {
        setLoading(true);
        const endpoint = 'https://europe-west1-design-connect.cloudfunctions.net/sendEmail';
        try {
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    to: designerEmail,
                    subject: 'U heeft een nieuwe aanvraag via Design Connect',
                    text: `Beste ${designerName},\n\nU heeft een nieuwe aanvraag via Design Connect! Zie hieronder de gegevens en het bericht:\n\nNaam: ${name}\n\nTelefoon: ${phone}\n\nEmailadres: ${email}\n\nBericht: ${question}\n\nMet vriendelijke groet,\n\nDESIGN CONNECT\n+31 6 3735 6180\ninfo@design-connect.com | www.design-connect.com\nKVK: 93100892 | IBAN: NL47 RABO 0383 0341 67 | BTW: NL 8662 765 67 B01`
                }),
            });

            if (!response.ok) throw new Error('Network response was not ok');
            setSnackbarMessage('Email succesvol verzonden');
            setSeverity('success');
            setSnackbarOpen(true);
            handleClose();
        } catch (error) {
            console.error('Error sending email:', error);
            setSnackbarMessage('Fout bij het verzenden van de email');
            setSeverity('error');
            setSnackbarOpen(true);
        }
        setLoading(false);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        await sendEmailToDesigner();
    };

    return (
        <>
            <Button variant="outlined" sx={{marginBottom: 2}} onClick={handleOpen}>
                Neem contact op
            </Button>
            <Dialog open={open} onClose={handleClose} PaperProps={{component: 'form', onSubmit: handleSubmit}}>
                <DialogTitle>Contacteer de ontwerper</DialogTitle>
                <IconButton aria-label="close" onClick={handleClose}
                            sx={{position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500]}}>
                    <CloseIcon/>
                </IconButton>
                <DialogContent>
                    <DialogContentText>
                        Wil je contact opnemen met deze ontwerper? Laat dan hier je email en je vraag achter.
                    </DialogContentText>
                    <TextField autoFocus required margin="dense" label="Naam" fullWidth value={name}
                               onChange={handleNameChange} sx={{marginBottom: 2}}/>
                    <TextField required margin="dense" label="Email" type="email" fullWidth value={email}
                               onChange={handleEmailChange} sx={{marginBottom: 2}}/>
                    <TextField required margin="dense" label="Telefoon" fullWidth value={phone}
                               onChange={handlePhoneChange} sx={{marginBottom: 2}}/>
                    <TextField required margin="dense" label="Vraag" fullWidth multiline rows={4} value={question}
                               onChange={handleQuestionChange} sx={{marginBottom: 2}}/>
                    <DialogActions>
                        <Button type="submit" variant="outlined">Verstuur</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            <Backdrop open={loading} sx={{color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1}}>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleClose} severity={severity} variant="filled" sx={{width: '100%'}}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

export default ContactDesignerDialog;
