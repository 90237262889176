import {DownloadUrl} from "../utils/DownloadUrl";
import defaultProfilePic from '../imgs/LogoIcon.png';
import {Designer} from "../interfaces/Designer";

export async function fetchProjectPictures(designer: Designer, selectedProjectId: string | null) {
    if (!selectedProjectId) {
        return []; // Return empty array if no project is selected
    }

    const projectDetails = designer.projects[selectedProjectId];
    if (!projectDetails) {
        return []; // Return empty array if selected project details are not found
    }

    const folderName = projectDetails.project_images;
    const encodedDesignerId = encodeURIComponent(designer.designerId);
    const encodedFolderName = encodeURIComponent(folderName);

    try {
        // Fetch project pictures only for the selected project
        const response = await fetch(`https://europe-west1-design-connect.cloudfunctions.net/listProjectPictures?designerId=${encodedDesignerId}&folderName=${encodedFolderName}`);
        if (!response.ok) throw new Error('Failed to fetch project pictures');
        const fileNamesResponse = await response.json();
        const fileNames: string[] = fileNamesResponse.data || [];

        const processedProjectPictures: string[] = await Promise.all(fileNames.map(async (fileName: string) =>
            DownloadUrl('designers', designer.designerId, 'project_pictures', fileName, folderName).catch(error => {
                console.error(`Error fetching project picture URL for ${selectedProjectId}:`, error);
                return defaultProfilePic; // Default picture in case of error
            })
        ));

        // Filter out the defaultProfilePic from the processedProjectPictures array
        return processedProjectPictures.filter(pic => pic !== defaultProfilePic);
    } catch (error) {
        console.error(`Error listing project pictures for ${selectedProjectId}:`, error);
        return []; // Return empty array in case of error
    }
}
