// firebaseUtils.js

import { getDownloadURL, getStorage, listAll, ref } from 'firebase/storage';
import CryptoJS from 'crypto-js';

// Initialize Firebase Storage
const storage = getStorage();

const secretKey = "your-secret-key"; // Replace with your actual secret key

export async function getRandomImageUrl() {
    const today = new Date();
    const dateString = today.toDateString(); // Get a string representation of the date (ignoring time)

    // Check if there's a stored encrypted image URL for today's date
    const encryptedImageUrl = localStorage.getItem(dateString);

    if (encryptedImageUrl) {
        try {
            // Decrypt the stored image URL
            const bytes = CryptoJS.AES.decrypt(encryptedImageUrl, secretKey);
            const imageUrl = bytes.toString(CryptoJS.enc.Utf8);
            if (imageUrl) {
                return imageUrl;
            }
        } catch (error) {
            console.error('Error decrypting image URL:', error);
        }
    }

    // If there's no stored image URL for today's date, fetch a new random image
    const storageRef = ref(storage, 'homepage_inspiration');
    const imagesRef = await listAll(storageRef);
    const randomIndex = Math.floor(Math.random() * imagesRef.items.length);
    const randomImageRef = imagesRef.items[randomIndex];
    const imageUrl = await getDownloadURL(randomImageRef);

    // Encrypt and store the selected image URL for today's date in local storage
    const encryptedImageUrlNew = CryptoJS.AES.encrypt(imageUrl, secretKey).toString();
    localStorage.setItem(dateString, encryptedImageUrlNew);

    return imageUrl;
}
